import axios, { AxiosRequestConfig } from "axios";

const axiosClient = axios.create({
  // baseURL: process.env.REACT_APP_API,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let res = error.response;
    if (res.status === 401) {
      window.location.href = process.env.REACT_APP_URL + "/login";
    } else if (res.status === 403) {
      window.location.href = process.env.REACT_APP_URL + "/";
    } else if (res.status === 409) {
      window.location.href = process.env.REACT_APP_URL + "/email/verify";
    }
    // console.error("Looks like there was a problem. Status Code: " + res.status);
    return Promise.reject(error);
  },
);

axiosClient.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = JSON.parse(window.localStorage.getItem("user") || "")?.token || "";
  const url = JSON.parse(window.localStorage.getItem("tenant") || "")?.api_url || process.env.REACT_APP_API;
  config.headers!.Authorization = token;
  config.baseURL = url;
  return config;
});

export default axiosClient;
