interface IAboutSection {
    type: string;
}
const AboutSection = ({ type }: IAboutSection ) => {
    return (
        <>
            {type === "istorie" && (
                <>
                    <div className="section-despre">
                        <h1>Despre Raportul BRIO</h1>
                        <div className="subsection">
                            <h3>Scopul raportului</h3>
                            <div className="subsection-content">
                                <p>
                                    Acest raport descrie nivelul de cunoștințe și de competențe la disciplina Istorie pentru elevul
                                    testat. El se bazează pe BRIO®, sistemul de testare standardizată pentru accelerarea performanței în
                                    învățământ.
                                </p>
                            </div>
                        </div>
                        <div className="subsection">
                            <h3>Utilizare </h3>
                            <div className="subsection-content">
                                <p>
                                    Raportul se adresează părinților, elevilor și/sau profesorilor care vor să afle stadiul de pregătire a
                                    elevului evaluat prin sistemul BRIO.
                                </p>
                                <p>
                                    Raportul realizat în urma testării reprezintă o analiză generală a gradului de pregătire la disciplina
                                    Istorie, cuprinzând informații relevante despre ariile cu rezultate bune (punctele tari) și ariile cu
                                    rezultate slabe (punctele vulnerabile) ale școlarului la această disciplină.
                                </p>
                                <p>
                                    Itemii propuși în cadrul testelor BRIO sunt concepuți pe baza programei școlare obligatorii aprobate
                                    de Ministerul Educației Naționale.
                                </p>
                                <p>
                                    Testarea poate fi făcută și la clasă, întrucât testele se bazează pe principiul IRT (Item Response
                                    Theory) care ajută la minimizarea efectelor trișării prin oferirea de seturi unice de întrebări pentru
                                    fiecare elev în parte. Față de testele fixe, unde persoanele evaluate ar putea avea posibilitatea de
                                    a-și împărtăși întrebările, testele care utilizează principiul IRT elimină posibilitățile de a obține
                                    avantaje neloiale. Mai mult IRT permite testarea adaptativă, astfel un test devine mai mult sau mai
                                    puțin dificil în funcție de performanța candidatului.
                                </p>
                                <p>
                                    Prin urmare, pe baza raportului se poate stabili nivelul actual de cunoștințe și competențe al
                                    elevului, se pot stabili ariile către care elevul evaluat ar trebui să își canalizeze eforturile de
                                    învățare și se pot lua decizii privind intervențiile pedagogice necesare. De asemenea, raportul
                                    permite monitorizarea progresului unui elev, atunci când acesta este comparat cu alte testări BRIO.
                                </p>
                            </div>
                        </div>
                        <div className="subsection">
                            <h3>Fundament</h3>
                            <div className="subsection-content">
                                <p>
                                    În ciuda acurateței și sofisticării sistemului de testare BRIO, care îl fac probabil cel mai
                                    performant sistem de testare educațională din România, este recomandabil ca, pentru decizii cu impact
                                    mare asupra elevului, rezultatele din raport să fie luate în considerare coroborat cu informații
                                    suplimentare precum notele școlare, opinia profesorului de la clasă sau a altor cadre didactice,
                                    intențiile și opiniile părinților, discuții cu elevul însuși. Prin coroborarea rezultatelor obținute
                                    la testele BRIO cu informațiile primite de la toți actorii cu care elevul relaționează (profesori,
                                    părinți), se poate obține o imagine cât mai clară asupra situației/nivelului școlar.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="section-despre">
                        <div className="subsection">
                            <h3>Secțiunile raportului</h3>
                            <div className="subsection-content">
                                <p>Raportul BRIO® are următoarele componente:</p>
                                <ol>
                                    <li>Secțiune introductivă</li>
                                    <li>Secțiune de prezentare a rezultatelor generale</li>
                                    <li>Secțiune de prezentare a rezultatelor detaliate</li>
                                </ol>
                                <p>Secțiunea introductivă este chiar aceasta pe care o citiți acum.</p>
                                <p>
                                    Secțiunea de prezentare a rezultatelor generale arată rezultatul general al elevului testat pentru
                                    istorie, precum și pentru principalele ramuri ale acestei discipline, atunci când acestea se studiază
                                    la clasă în mod explicit separat.
                                </p>
                            </div>
                        </div>
                        <div className="subsection">
                            <h3>Scorul BRIO® </h3>
                            <div className="subsection-content">
                                <p>
                                    Rezultatele sunt prezentate sub forma scorului BRIO, un scor care poate varia între 0 (foarte slab) și
                                    100 (foarte competent). Cu cât scorul este mai mare, cu atât el indică un nivel de cunoștințe și
                                    competențe mai ridicat.
                                </p>
                                <p>
                                    Scorul BRIO® este un scor-centilă: el arată cum se compară elevul testat cu toți ceilalți copii din
                                    România care parcurg aceeași clasă ca și el. De exemplu, pentru un elev care se află la centila 70 (a
                                    obținut scorul BRIO® 70) vom spune că 30% dintre elevii din România (de acea vârstă sau clasă) sunt
                                    mai buni decât el în timp ce 70% sunt mai slabi sau egali cu el. Totodată, un copil care are centila
                                    90 (scorul BRIO® 90) are în fața sa doar 10% din populația școlară comparabilă iar 90% dintre elevii
                                    de aceeași clasă sunt mai slabi decât el – acesta este un scor foarte bun.
                                </p>
                            </div>
                        </div>
                        <div className="subsection">
                            <h3>Profilul competențelor</h3>
                            <div className="subsection-content">
                                <p>
                                    În secțiunea Profilul competențelor sunt prezentate scorurile generale pentru toate dimensiunile
                                    (capitolele) măsurate, sub formă de profil. Scorurile pentru fiecare dimensiune variază între 0 și 100
                                    și permit observarea per ansamblu a punctelor mai slabe și a punctelor celor mai puternice pentru
                                    elevul testat.
                                </p>
                            </div>
                        </div>
                        <div className="subsection">
                            <h3>Descrierile rezultatelor</h3>
                            <div className="subsection-content">
                                <p>
                                    În secțiunea de prezentare a rezultatelor detaliate sunt analizate pe rând rezultatele elevului pentru
                                    fiecare dimensiune. Astfel, se explică ce anume a fost măsurat în cadrul fiecărei dimensiuni, care
                                    este scorul obținut de elevul testat și care este semnificația acestui scor. Informația poate fi
                                    utilizată de elevi, părinți și profesori pentru a înțelege în detaliu ce cunoștințe și competențe are
                                    elevul, dar și ce cunoștințe și competențe îi lipsesc acestuia (conform cerințelor programei școlare
                                    aferente clasei în care se află). Totodată, pe baza rezultatelor obținute de elev, se pot dezvolta
                                    modalități personalizate de intervenție educațională.
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {(type === "matematica" || type === "evaluare-matematica") && (
                <>
                    <div className="section-despre">
                        <h1>Despre Raportul BRIO</h1>
                        <div className="subsection">
                            <h3>Scopul raportului</h3>
                            <div className="subsection-content">
                                <p>Acest raport descrie nivelul de cunoștințe și competențe de matematică pentru elevul testat.</p>
                            </div>
                        </div>
                        <div className="subsection">
                            <h3>Utilizare </h3>
                            <div className="subsection-content">
                                <p>
                                    Raportul se adresează părinților, elevilor și / sau profesorilor care vor să afle stadiul de pregătire
                                    al elevului evaluat prin sistemul BRIO.
                                </p>
                                <p>
                                    El reprezintă o analiză generală a gradului de pregătire la matematică și cuprinde totodată informații
                                    importante despre ariile cu rezultate bune (punctele tari) și ariile cu rezultate slabe (punctele
                                    vulnerabile) ale școlarului în cadrul acestei discipline.
                                </p>
                                <p>
                                    Prin urmare, pe baza raportului se poate stabili nivelul actual de cunoștințe și competențe al
                                    elevului, se pot stabili ariile pe care ar fi oportun ca elevul evaluat să se concentreze în învățare
                                    și se pot lua decizii privind intervențiile pedagogice necesare. De asemenea, raportul permite
                                    monitorizarea progresului unui elev, atunci când acesta este comparat cu alte testări BRIO.
                                </p>
                                <p>
                                    În ciuda acurateței și sofisticării sistemului de testare BRIO, care îl fac probabil cel mai
                                    performant sistem de testare educațională din România, este recomandabil ca, pentru decizii cu impact
                                    mare asupra elevului, rezultatele din raport să fie luate în considerare doar coroborat cu informații
                                    suplimentare precum notele școlare, opinia profesorului de la clasă sau a altor cadre didactice,
                                    intențiile și opiniile părinților, discuții cu elevul însuși. Prin coroborarea rezultatelor obținute
                                    la testele BRIO cu informațiile primite de la toți actorii cu care elevul relaționează (profesori,
                                    părinți), se poate obține o imagine cât mai clară asupra situației/nivelului școlar.
                                </p>
                            </div>
                        </div>
                        <div className="subsection">
                            <h3>Fundament</h3>
                            <div className="subsection-content">
                                <p>
                                    Acest raport este bazat pe BRIO, sistemul de testare standardizată pentru accelerarea performanței în
                                    învățământ. Sistemul BRIO® este dezvoltat de specialiști în testare educațională la nivelul celor mai
                                    actuale standarde internaționale din domeniu. El include o bancă foarte mare de itemi și utilizează o
                                    tehnologie statistică de ultimă oră — Item Response Theory.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="section-despre">
                        <div className="subsection">
                            <h3>Secțiunile raportului</h3>
                            <div className="subsection-content">
                                <p>Raportul BRIO® are următoarele componente:</p>
                                <ol>
                                    <li>Secțiune introductivă</li>
                                    <li>Secțiune de prezentare a rezultatelor generale</li>
                                    <li>Secțiune de prezentare a rezultatelor detaliate</li>
                                </ol>
                                <p>Secțiunea introductivă este chiar aceasta pe care o citiți acum.</p>
                                <p>
                                    Secțiunea de prezentare a rezultatelor generale arată rezultatul general al elevului testat pentru
                                    matematică, precum și pentru principalele ramuri ale acestei discipline (de exemplu, geometrie,
                                    algebră sau analiză matematică), atunci când acestea se studiază la clasă în mod explicit separat.
                                </p>
                            </div>
                        </div>
                        <div className="subsection">
                            <h3>Scorul BRIO® </h3>
                            <div className="subsection-content">
                                <p>
                                    Rezultatele sunt prezentate sub forma scorului BRIO, un scor care poate varia între 0 (foarte slab) și
                                    100 (foarte competent). Cu cât scorul este mai mare, cu atât el indică un nivel de cunoștințe și
                                    competențe mai ridicat.
                                </p>
                                <p>
                                    Scorul BRIO® este un scor-centilă: el arată cum se compară elevul testat cu toți ceilalți copii din
                                    România care parcurg aceeași clasă ca și el. De exemplu, pentru un elev care se află la centila 70 (a
                                    obținut scorul BRIO® 70) vom spune că 30% dintre elevii din România (de acea vârstă sau clasă) sunt
                                    mai buni decât el în timp ce 70% sunt mai slabi sau egali cu el. Totodată, un copil care are centila
                                    90 (scorul BRIO® 90) are în fața sa doar 10% din populația școlară comparabilă iar 90% dintre elevii
                                    de aceeași clasă sunt mai slabi decât el – acesta este un scor foarte bun.
                                </p>
                            </div>
                        </div>
                        <div className="subsection">
                            <h3>Profilul competențelor</h3>
                            <div className="subsection-content">
                                <p>
                                    În secțiunea Profilul competențelor sunt prezentate scorurile generale pentru toate dimensiunile
                                    (capitolele) măsurate, sub formă de profil. Scorurile pentru fiecare dimensiune variază între 0 și 100
                                    și permit observarea per ansamblu a punctelor mai slabe și a punctelor celor mai puternice pentru
                                    elevul testat.
                                </p>
                            </div>
                        </div>
                        <div className="subsection">
                            <h3>Descrierile rezultatelor</h3>
                            <div className="subsection-content">
                                <p>
                                    În secțiunea de prezentare a rezultatelor detaliate sunt analizate pe rând rezultatele elevului pentru
                                    fiecare dimensiune. Astfel, se explică ce anume a fost măsurat în cadrul fiecărei dimensiuni, care
                                    este scorul obținut de elevul testat și care este semnificația acestui scor. Informația poate fi
                                    utilizată de elevi, părinți și profesori pentru a înțelege în detaliu ce cunoștințe și competențe are
                                    elevul, dar și ce cunoștințe și competențe îi lipsesc acestuia (conform cerințelor programei școlare
                                    aferente clasei în care se află). Totodată, pe baza rezultatelor obținute de elev, se pot dezvolta
                                    modalități personalizate de intervenție educațională.
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {(type === "romana" || type === "evaluare-romana") && (
                <>
                    <div className="section-despre">
                        <h1>Despre Raportul BRIO</h1>
                        <div className="subsection">
                            <h3>Scopul raportului</h3>
                            <div className="subsection-content">
                                <p>
                                    Acest raport descrie nivelul de cunoștințe și de competențe la disciplina limba și literatura română
                                    pentru elevul testat. El se bazează pe BRIO®, sistemul de testare standardizată pentru accelerarea
                                    performanței în învățământ.
                                </p>
                            </div>
                        </div>
                        <div className="subsection">
                            <h3>Utilizare </h3>
                            <div className="subsection-content">
                                <p>
                                    Raportul se adresează părinților, elevilor și / sau profesorilor care vor să afle stadiul de pregătire
                                    al elevului evaluat prin sistemul BRIO.
                                </p>
                                <p>
                                    Raportul realizat în urma testării reprezintă o analiză generală a gradului de pregătire la disciplina
                                    limba și literatura română, cuprinzând informații relevante despre ariile cu rezultate bune (punctele
                                    tari) și ariile cu rezultate slabe (punctele vulnerabile) ale școlarului la această disciplină.
                                </p>
                                <p>
                                    Itemii propuși în cadrul testelor BRIO sunt concepuți pe baza programei școlare obligatorii aprobate
                                    de Ministerul Educației Naționale.
                                </p>
                                <p>
                                    Testarea poate fi făcută și la clasă, întrucât testele se bazează pe principiul IRT (Item Response
                                    Theory) care ajută la minimizarea efectelor trișării prin oferirea de seturi unice de întrebări pentru
                                    fiecare elev în parte. Față de testele fixe, unde persoanele evaluate ar putea avea posibilitatea de
                                    a-și împărtăși întrebările, testele care utilizează principiul IRT elimină posibilitățile de a obține
                                    avantaje neloiale. Mai mult IRT permite testarea adaptativă, astfel un test devine mai mult sau mai
                                    puțin dificil în funcție de performanța candidatului.
                                </p>
                                <p>
                                    Prin urmare, pe baza raportului se poate stabili nivelul actual de cunoștințe și competențe al
                                    elevului, se pot stabili ariile către care elevul evaluat ar trebui să își canalizeze eforturile de
                                    învățare și se pot lua decizii privind intervențiile pedagogice necesare. De asemenea, raportul
                                    permite monitorizarea progresului unui elev, atunci când acesta este comparat cu alte testări BRIO.
                                </p>
                            </div>
                        </div>
                        <div className="subsection">
                            <h3>Fundament</h3>
                            <div className="subsection-content">
                                <p>
                                    În ciuda acurateței și sofisticării sistemului de testare BRIO, care îl fac probabil cel mai
                                    performant sistem de testare educațională din România, este recomandabil ca, pentru decizii cu impact
                                    mare asupra elevului, rezultatele din raport să fie luate în considerare coroborat cu informații
                                    suplimentare precum notele școlare, opinia profesorului de la clasă sau a altor cadre didactice,
                                    intențiile și opiniile părinților, discuții cu elevul însuși. Prin coroborarea rezultatelor obținute
                                    la testele BRIO cu informațiile primite de la toți actorii cu care elevul relaționează (profesori,
                                    părinți), se poate obține o imagine cât mai clară asupra situației/nivelului școlar.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="section-despre">
                        <div className="subsection">
                            <h3>Secțiunile raportului</h3>
                            <div className="subsection-content">
                                <p>Raportul BRIO® are următoarele componente:</p>
                                <ol>
                                    <li>Secțiune introductivă</li>
                                    <li>Secțiune de prezentare a rezultatelor generale</li>
                                    <li>Secțiune de prezentare a rezultatelor detaliate</li>
                                </ol>
                                <p>Secțiunea introductivă este chiar aceasta pe care o citiți acum.</p>
                                <p>
                                    Secțiunea de prezentare a rezultatelor generale arată rezultatul general al elevului testat pentru
                                    limba română, precum și pentru principalele ramuri ale acestei discipline, atunci când acestea se
                                    studiază la clasă în mod explicit separat.
                                </p>
                            </div>
                        </div>
                        <div className="subsection">
                            <h3>Scorul BRIO® </h3>
                            <div className="subsection-content">
                                <p>
                                    Rezultatele sunt prezentate sub forma scorului BRIO, un scor care poate varia între 0 (foarte slab) și
                                    100 (foarte competent). Cu cât scorul este mai mare, cu atât el indică un nivel de cunoștințe și
                                    competențe mai ridicat.
                                </p>
                                <p>
                                    Scorul BRIO® este un scor-centilă: el arată cum se compară elevul testat cu toți ceilalți copii din
                                    România care parcurg aceeași clasă ca și el. De exemplu, pentru un elev care se află la centila 70 (a
                                    obținut scorul BRIO® 70) vom spune că 30% dintre elevii din România (de acea vârstă sau clasă) sunt
                                    mai buni decât el în timp ce 70% sunt mai slabi sau egali cu el. Totodată, un copil care are centila
                                    90 (scorul BRIO® 90) are în fața sa doar 10% din populația școlară comparabilă iar 90% dintre elevii
                                    de aceeași clasă sunt mai slabi decât el – acesta este un scor foarte bun.
                                </p>
                            </div>
                        </div>
                        <div className="subsection">
                            <h3>Profilul competențelor</h3>
                            <div className="subsection-content">
                                <p>
                                    În secțiunea Profilul competențelor sunt prezentate scorurile generale pentru toate dimensiunile
                                    (capitolele) măsurate, sub formă de profil. Scorurile pentru fiecare dimensiune variază între 0 și 100
                                    și permit observarea per ansamblu a punctelor mai slabe și a punctelor celor mai puternice pentru
                                    elevul testat.
                                </p>
                            </div>
                        </div>
                        <div className="subsection">
                            <h3>Descrierile rezultatelor</h3>
                            <div className="subsection-content">
                                <p>
                                    În secțiunea de prezentare a rezultatelor detaliate sunt analizate pe rând rezultatele elevului pentru
                                    fiecare dimensiune. Astfel, se explică ce anume a fost măsurat în cadrul fiecărei dimensiuni, care
                                    este scorul obținut de elevul testat și care este semnificația acestui scor. Informația poate fi
                                    utilizată de elevi, părinți și profesori pentru a înțelege în detaliu ce cunoștințe și competențe are
                                    elevul, dar și ce cunoștințe și competențe îi lipsesc acestuia (conform cerințelor programei școlare
                                    aferente clasei în care se află). Totodată, pe baza rezultatelor obținute de elev, se pot dezvolta
                                    modalități personalizate de intervenție educațională.
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default AboutSection;
