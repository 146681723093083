import React from "react";
import TextField from "@mui/material/TextField";
import { Controller, useFormContext } from "react-hook-form";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import ErrorsList from "components/ui/ErrorsList";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import AutocompleteAPI, {AutocompleteTextFieldStyled, PaperStyled} from "./AutocompleteAPI";
import { AxiosResponse } from "axios";
import { IOptions } from "api/pages/teachers";
import InputAdornment from "@mui/material/InputAdornment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "@mui/material/IconButton";
import AutocompleteAPIField from "./AutocompleteAPIField";

export const TextFieldStyled = styled(TextField)(({ theme }) => ({
  margin: 0,
  borderRadius: 15,
  "& label": {
    color: theme.palette.text.secondary,
    paddingLeft: 12,
    lineHeight: "1.2em",
  },
  "& label.MuiInputLabel-shrink": {
    display: "none",
  },
  "& legend": {
    display: "none",
  },
  "& .MuiInputBase-input, & .MuiInputBase-input:focus, MuiInputBase-input:-webkit-autofill": {
    borderRadius: 15,
    backgroundColor:  theme.palette.secondary.contrastText,
    padding: "14px 14px 14px 23px",
    color: theme.palette.text.secondary,
  },
  "& .MuiMenu-list": {
    backgroundColor: "#fff",
  },
  "& .Mui-error": {
    "& .MuiInputBase-input": {
      border: "1px solid #9D0000",
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 15,
    "& fieldset": {
      border: "none",
    },
  },
  "&.dark": {
    "& label": {
      color: theme.palette.text.secondary,
    },
    "& .MuiInputBase-input": {
      borderRadius: 15,
      backgroundColor: theme.palette.secondary.contrastText,
      padding: "14px 14px 14px 23px",
      color: theme.palette.text.secondary,
    },
  },
  "& .MuiSelect-iconOutlined": {
    color: theme.palette.primary.light,
  },
}));

export const IconTextFieldStyled = styled(TextField)(({ theme }) => ({
  margin: 0,
  backgroundColor: theme.palette.secondary.contrastText,
  position: "relative",
  borderRadius: 15,
  "& label": {
    color: theme.palette.text.secondary,
    paddingLeft: 12,
    lineHeight: "1.2em",
  },
  "& label.MuiInputLabel-shrink": {
    display: "none",
  },
  "& legend": {
    display: "none",
  },
  "& .MuiInputBase-input, & .MuiInputBase-input:focus": {
    color: theme.palette.text.secondary,
    padding: "14px 14px 14px 23px",
    // padding: useMediaQuery(theme.breakpoints.up("sm")) ? "14px 14px 14px 23px" : "8.5px 14px 8.5px 23px",
  },
  "& .MuiSelect-iconOutlined": {
    color: theme.palette.primary.light,
  },
  "& .Mui-error": {
    "& .MuiInputBase-input": {
      border: "1px solid #9D0000",
      borderRadius: 15,
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 15,
    "& fieldset": {
      border: "none",
      borderRadius: 15,
      backgroundColor: "#fff",
      zIndex: -1,
      top: 0,
    },
  },
  "&.dark": {
    "& label": {
      color: theme.palette.text.secondary,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
        borderRadius: 15,
        backgroundColor: theme.palette.secondary.contrastText,
        zIndex: -1,
        top: 0,
      },
      "& .MuiInputBase-input": {
        color: theme.palette.text.secondary,
      },
    },
  },
}));

export const IconTextFieldSmallStyled = styled(TextField)(({ theme }) => ({
  margin: 0,
  backgroundColor: theme.palette.secondary.contrastText,
  position: "relative",
  borderRadius: 15,
  "& label": {
    color: theme.palette.text.secondary,
    paddingLeft: 12,
    lineHeight: "1.2em",
  },
  "& label.MuiInputLabel-shrink": {
    display: "none",
  },
  "& legend": {
    display: "none",
  },
  "& .MuiInputBase-input, & .MuiInputBase-input:focus": {
    color: theme.palette.text.secondary,
    // padding: "14px 14px 14px 23px",

    // padding: useMediaQuery(theme.breakpoints.up("sm")) ? "14px 14px 14px 23px" : "8.5px 14px 8.5px 23px",
  },
  "& .MuiSelect-iconOutlined": {
    color: theme.palette.primary.light,
  },
  "& .Mui-error": {
    "& .MuiInputBase-input": {
      border: "1px solid #9D0000",
      borderRadius: 15,
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 15,
    "& fieldset": {
      border: "none",
      borderRadius: 15,
      backgroundColor: "#fff",
      zIndex: -1,
      top: 0,
    },
  },
  "&.dark": {
    "& label": {
      color: theme.palette.text.secondary,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
        borderRadius: 15,
        backgroundColor: theme.palette.secondary.contrastText,
        zIndex: -1,
        top: 0,
      },
      "& .MuiInputBase-input": {
        color: theme.palette.text.secondary,
      },
    },
  },
}));

export const IconTextFieldBgStyled = styled(TextField)(({ theme }) => ({
  margin: 0,
  backgroundColor: theme.palette.background.paper,
  position: "relative",
  borderRadius: 15,
  "& label": {
    color: theme.palette.text.secondary,
    paddingLeft: 12,
    lineHeight: "1em",
  },
  "& label.MuiInputLabel-shrink": {
    display: "none",
  },
  "& legend": {
    display: "none",
  },
  "& .MuiInputBase-input, & .MuiInputBase-input:focus": {
    color: theme.palette.text.secondary,
    padding: "4px 23px",

    // padding: useMediaQuery(theme.breakpoints.up("sm")) ? "14px 14px 14px 23px" : "8.5px 14px 8.5px 23px",
  },
  "& .MuiSelect-iconOutlined": {
    color: theme.palette.primary.light,
  },
  "& .Mui-error": {
    "& .MuiInputBase-input": {
      border: "1px solid #9D0000",
      borderRadius: 15,
    },
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
      borderRadius: 15,
      backgroundColor: "#fff",
      zIndex: -1,
      top: 0,
    },
  },
  "&.dark": {
    "& label": {
      color: theme.palette.text.secondary,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
        borderRadius: 15,
        backgroundColor: theme.palette.secondary.contrastText,
        zIndex: -1,
        top: 0,
      },
      "& .MuiInputBase-input": {
        color: theme.palette.text.secondary,
        padding: "4px",
      },
    },
  },
}));

interface Option {
  value: string | number;
  label: string;
  [key: string]: any;
}
interface Props {
  name: string;
  type: string;
  value?: string | number;
  valueAutocomplete?: object | null;
  valueAutocompleteField?: object | undefined;
  defaultValue?: string | number | any[] | null;
  AutocompleteFieldDefaultValue?: string | number | any[] | undefined;
  label?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  errorMessage?: string | undefined;
  error?: boolean;
  className?: string;
  options?: Option[];
  multiple?: boolean;
  onChange?: (e: any) => void;
  onChangeAutocomplete?: (event: any, value: any) => void;
  onInputChange?: (e: any) => void;
  optionsAPI?: (options: IOptions) => Promise<AxiosResponse<any, any>>;
  filterOptions?: any;
  loading?: boolean;
  onClick?: () => void;
  controlled?: boolean;
  inputProps?: object;
  disableClearable?: boolean;
  disabled?: boolean;
}

const FormField = ({
  name,
  type = "text",
  value = "",
  valueAutocomplete = null,
  AutocompleteFieldDefaultValue = undefined,
  defaultValue,
  label,
  autoComplete = "",
  autoFocus = false,
  errorMessage = "",
  error = false,
  className = "",
  options = [],
  multiple = false,
  onChange = (e: any) => {},
  onChangeAutocomplete,
  onInputChange,
  optionsAPI,
  filterOptions,
  loading,
  onClick,
  controlled = false,
  inputProps,
  disableClearable = false,
    disabled = false,
}: Props) => {
  const methods = useFormContext();

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Box width="100%">
      <>
        {(type === "text" || type === "password" || type === "hidden" || type === "number") && (
          <Controller
            name={name}
            control={methods.control}
            defaultValue={defaultValue}
            render={({ field }) => (
              <TextFieldStyled
                error={error}
                fullWidth
                margin="normal"
                label={label}
                autoComplete={autoComplete}
                autoFocus={autoFocus}
                type={type}
                {...field}
                className={className}
                InputProps={{ inputProps: inputProps }}
              />
            )}
          />
        )}
        {type === "controlled-number" && (
          <Controller
            name={name}
            control={methods.control}
            defaultValue={defaultValue}
            render={({ field }) => (
              <TextFieldStyled
                {...field}
                error={error}
                fullWidth
                margin="normal"
                label={label}
                autoComplete={autoComplete}
                autoFocus={autoFocus}
                value={value}
                onChange={(e) => {
                  onChange(e);
                  methods.clearErrors("expire");
                }
                }
                type="number"
                className={className}
                InputProps={{ inputProps: inputProps }}
              />
            )}
          />
        )}
        {type === "text-icon" && (
          <Controller
            name={name}
            control={methods.control}
            defaultValue={defaultValue}
            render={({ field }) => (
              <IconTextFieldSmallStyled
                error={error}
                size="small"
                fullWidth
                margin="normal"
                label={label}
                autoComplete={autoComplete}
                autoFocus={autoFocus}
                type="text"
                // sx={{padding: isSmallScreen ? "14px 14px 14px 23px" : "8.5px 14px 8.5px 23px"}}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{ visibility: value ? "visible" : "hidden" }}
                        onClick={onClick}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        <FontAwesomeIcon style={{ fontSize: "1rem", color: "#9D0000" }} icon={"xmark"} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...field}
                className={className}
              />
            )}
          />
        )}
        {type === "autocomplete_api" && typeof optionsAPI !== "undefined" && (
          <AutocompleteAPI
            name={name}
            defaultValue={defaultValue}
            label={label}
            optionsAPI={optionsAPI}
            multiple={multiple}
            filterOptions={filterOptions}
            onChange={onChangeAutocomplete}
            controlled={controlled}
            value={valueAutocomplete}
            error={error}
          />
        )}
        {type === "autocomplete_field" && typeof optionsAPI !== "undefined" && (
          <AutocompleteAPIField
            name={name}
            defaultValue={AutocompleteFieldDefaultValue}
            label={label}
            optionsAPI={optionsAPI}
            multiple={multiple}
            filterOptions={filterOptions}
            onChange={onChangeAutocomplete}
          />
        )}
        {type === "autocomplete" && (
          <Controller
            name={name}
            control={methods.control}
            defaultValue={defaultValue}
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={options}
                blurOnSelect={true}
                getOptionLabel={(option) => option.label}
                PaperComponent={PaperStyled}
                // renderOption={(option) => (
                //   <span>
                //     {option.label}
                //   </span>
                // )}
                // getOptionSelected={(option: Option[], value: Option[]) => _.isEqual(option, value)}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderOption={(props, option) => {
                  return (
                      <li {...props} key={option.value}>
                        {option.label}
                      </li>
                  );
                }}
                renderInput={(params) => (
                  <AutocompleteTextFieldStyled
                    {...params}
                    error={error}
                    label={label}
                    variant="filled"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                onChange={(_, data) => field.onChange(data)}
                onInputChange={onInputChange}
                multiple={multiple}
                loading={loading}
              />
            )}
          />
        )}
        {type === "autocomplete-controlled" && (
            <Controller
                name={name}
                control={methods.control}
                defaultValue={defaultValue}
                render={({ field }) => (
                    <Autocomplete
                        {...field}
                        options={options}
                        blurOnSelect={true}
                        getOptionLabel={(option) => option.label}
                        // popupIcon={
                        //   <Box sx={{ fontSize: "1.25rem" }} color="text.secondary">
                        //     <Icon icon="caret-down" fixedWidth={true} />
                        //   </Box>
                        // }
                        // clearIcon={
                        //   <Box sx={{ fontSize: "1.25rem" }} color="text.secondary">
                        //     <Icon icon="xmark" fixedWidth={true} />
                        //   </Box>
                        // }
                        PaperComponent={PaperStyled}
                        // renderOption={(option) => (
                        //   <span>
                        //     {option.label}
                        //   </span>
                        // )}
                        // getOptionSelected={(option: Option[], value: Option[]) => _.isEqual(option, value)}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        renderOption={(props, option) => {
                          return (
                              <li {...props} key={option.value}>
                                {option.label}
                              </li>
                          );
                        }}
                        renderInput={(params) => (
                            <AutocompleteTextFieldStyled
                                {...params}
                                error={error}
                                label={label}
                                variant="filled"
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                      <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                  ),
                                }}
                            />
                        )}
                        onChange={onChange}
                        onInputChange={onInputChange}
                        filterOptions={filterOptions}
                        multiple={multiple}
                        disableClearable={disableClearable}
                    />
                )}
            />
        )}
        {type === "controlled-select" && (
          <>
            <Controller
              name={name}
              control={methods.control}
              defaultValue={defaultValue}
              render={({ field }) => (
                <TextFieldStyled
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          backgroundColor: "primary.light",
                          borderRadius: "15px",
                        },
                      },
                    },
                  }}
                  fullWidth
                  select
                  margin="normal"
                  label={label}
                  autoComplete={autoComplete}
                  autoFocus={autoFocus}
                  {...field}
                  value={value}
                  className={className}
                  onChange={onChange}

                >
                  {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextFieldStyled>
              )}
            />
          </>
        )}
        {type === "controlled-select-small" && (
            <>
              <Controller
                  name={name}
                  control={methods.control}
                  defaultValue={defaultValue}
                  render={({ field }) => (
                      <IconTextFieldSmallStyled
                          disabled={disabled}
                          size="small"
                          SelectProps={{
                            MenuProps: {
                              PaperProps: {
                                sx: {
                                  backgroundColor: "primary.light",
                                  borderRadius: "15px",
                                },
                              },
                            },
                          }}
                          fullWidth
                          select
                          margin="normal"
                          label={label}
                          autoComplete={autoComplete}
                          autoFocus={autoFocus}
                          {...field}
                          value={value}
                          className={className}
                          onChange={onChange}

                      >
                        {options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                        ))}
                      </IconTextFieldSmallStyled>
                  )}
              />
            </>
        )}
        {type === "select" && (
          <Controller
            name={name}
            control={methods.control}
            defaultValue={defaultValue}
            render={({ field }) => (
              <TextFieldStyled
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      sx: {
                        backgroundColor: "primary.light",
                        borderRadius: "15px",
                      },
                    },
                  },
                }}
                fullWidth
                select
                margin="normal"
                label={label}
                autoComplete={autoComplete}
                autoFocus={autoFocus}
                {...field}
                className={className}
                error={error}
              >
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextFieldStyled>
            )}
          />
        )}
        {type === "select-small" && (
            <Controller
                name={name}
                control={methods.control}
                defaultValue={defaultValue}
                render={({ field }) => (
                    <IconTextFieldBgStyled
                        // size="small"
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              sx: {
                                backgroundColor: "primary.light",
                                borderRadius: "6px",
                              },
                            },
                          },
                        }}
                        fullWidth
                        select
                        margin="normal"
                        label={label}
                        autoComplete={autoComplete}
                        autoFocus={autoFocus}
                        {...field}
                        className={className}
                        error={error}
                    >
                      {options.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                      ))}
                    </IconTextFieldBgStyled>
                )}
            />
        )}
        <ErrorsList show={errorMessage !== ""}>{errorMessage}</ErrorsList>
      </>
    </Box>
  );
};

export default FormField;
