import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormField from "components/form/FormField";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAuth } from "hooks/useAuth";
import { IFormForgotPassword } from "components/login/_interfaces";
import { APIPostForgotPassword } from "api/pages/passwordReset";
import {APIGetSchoolsLogin} from "../../api/pages/schools";

const schema = yup
  .object({
  })
  .required();

const ForgotPasswordForm = () => {
  const { update } = useAuth();

  const methods = useForm<IFormForgotPassword>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IFormForgotPassword> = (data) => {
    const school = data.school ? data.school.slug : null;
    update("loading", { show: true, modal: true });

    APIPostForgotPassword({
      domain: school,
      email: data.email,
    })
      .then((response) => {
        if (response.data.success) {
          update("loading", { show: false });
          methods.clearErrors();
          let message = response.data.data.value;
          update("error", {
            show: true,
            message: message,
            title: "",
            icon: "fat envelope",
            closable: true,
            button: "ÎNCHIDE",
          });
          methods.reset({
            email: "",
          });
        } else {
          update("loading", { show: false });
          update("error", { show: true, code: "A002" });
        }
      })
      .catch((err) => {
        console.log("postLOGIN ERR", err);
        if (err.response.status === 400) {
          let errors = err.response.data.data;
          if (errors.domain) {
            methods.setError("domain", { type: "server", message: errors.domain[0] });
          }
          if (errors.email) {
            methods.setError("email", { type: "server", message: errors.email[0] });
          }
          if (errors.general) {
            methods.setError("email", { type: "server", message: errors.general[0] });
          }
          update("loading", { show: false });
        } else {
          update("error", { show: true, code: "A001" });
        }
      });
    update("loading", { show: true, modal: true });
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
          <Box py={1}>
            {/*<FormField*/}
            {/*  name="domain"*/}
            {/*  type="text"*/}
            {/*  defaultValue=""*/}
            {/*  label="Numele școlii"*/}
            {/*  autoFocus={false}*/}
            {/*  errorMessage={methods.formState.errors.domain?.message}*/}
            {/*/>*/}
            <FormField
                name="school"
                type="autocomplete_api"
                label="Caută școala..."
                optionsAPI={APIGetSchoolsLogin}
                defaultValue={null}
                multiple={false}
                filterOptions={(x: any) => x}
                errorMessage={methods.formState.errors.school?.message}
                error={!!methods.formState.errors.school?.message}
                autoFocus={true}
            />
          </Box>
          <Box py={1}>
            <FormField
              name="email"
              type="text"
              defaultValue=""
              label="Adresa de e-mail"
              autoComplete="email"
              autoFocus={false}
              errorMessage={methods.formState.errors.email?.message}
            />
          </Box>
          <Box
            sx={{
              mt: 1,
              mb: 3,
            }}
          >
            <Button type="submit" variant="contained">
              Trimite e-mail
            </Button>
          </Box>
        </form>
      </FormProvider>
    </>
  );
};

export default ForgotPasswordForm;
