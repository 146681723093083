import "components/reports/SchoolTestReport.css";
import { capitalize } from "utils/functions";
import {ReportLeftBottom, ReportLeftBottomXs, ReportSegmentsImage} from "utils/reportsFunctions";
import CoverPage from "../components/CoverPage";
import moment from "moment";
import Chart from "../components/Chart";
import AboutSection from "./AboutSection";
import ChartSubsection from "../components/ChartSubsection";
import ChartNoteWrapperBig from "../components/ChartNoteWrapperBig";
import TableRow from "../components/TableRow";
import MarkGroup from "../components/MarkGroup";
import Units from "../components/Units";
import SubcategorySection from "../components/SubcategorySection";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useEffect, useState} from "react";
import ScrollBar from "../../ui/ScrollBar";

const Report = ({ reportData, gradeLevels, graphData, skillsData }: any) => {
  let coverImage = "/images/reports/cover-istorie.png";
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const [left, setLeft] = useState(0);
  const [bottom, setBottom] = useState(0);
  const segmentsImage = ReportSegmentsImage(reportData.score, "digitalLiteracy");

  useEffect(() => {
    if(isSmallScreen) {
      setLeft(ReportLeftBottom(reportData.score, "school")[0]);
      setBottom(ReportLeftBottom(reportData.score, "school")[1]);
    } else {
      setLeft(ReportLeftBottomXs(reportData.score, "school")[0]);
      setBottom(ReportLeftBottomXs(reportData.score, "school")[1]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (reportData.type === "istorie") coverImage="/images/reports/cover-istorie.png";
  else if((reportData.type === "matematica" ||
      reportData.type === "bac-matematica" ||
      reportData.type === "evaluare-matematica") ) coverImage = "/images/reports/cover-img.png";
  else if(reportData.type === "romana" ||
      reportData.type === "evaluare-romana" ||
      reportData.type === "bac-romana") coverImage = "/images/reports/cover-romana.png"

  return (
    <>
      <div className="cover-bg">
        <div id="print_page" className="wrapper-cover">
          <CoverPage coverImage={coverImage} type="school">
            <>
              <div className="title">
                <h1>Raport de evaluare</h1>
                <h2>Sesiune #{reportData.test.id}</h2>
                <h1>Scor {reportData.score}</h1>
              </div>
              <h2>
                {reportData.test.title !== null
                  ? capitalize(reportData.test.title)
                  : reportData.test_mode.id < 3
                  ? capitalize(reportData.type)
                  : ""}
                {" "}
                {reportData.test_mode.id < 3 && (reportData.test.grade.name === "I" || reportData.test.grade.name === "0" ? reportData.test.grade.name : `clasa a ${reportData.test.grade.name}-a`)}
                {reportData.test_mode.id >= 3 && reportData.test.grade.name} |{" "}
                {moment(reportData.test.started_at).locale("ro").format("DD MMMM YYYY")}
              </h2>
            </>
          </CoverPage>

          <div className="pagebreak"></div>

          <AboutSection type={reportData.type} />

          <div className="pagebreak"></div>

          <div className="section-despre">
            <h1>Despre Scorul BRIO</h1>
            <Chart
              testMode={reportData.test_mode.id}
              left={left}
              bottom={bottom}
              testScore={reportData.score}
              segmentsImage={segmentsImage}
              big={true}
              type="school"
            />
            {gradeLevels.map((gradeLevel: any, index: any) => (
                <div key={index}>
                <ChartSubsection gradeLevel={gradeLevel} score={reportData.score} index={index} type="school" />
                </div>
            ))}
          </div>

          <div className="pagebreak"></div>
          {graphData.length > 0 && (
              <div className="section-despre">
                <h1>Profilul competențelor evaluate</h1>
                <ScrollBar forceVisible="x" autoHide={false}  >
                  <table className="tabel-note">
                    <thead>
                    <tr>
                      <th className="col-capitol">&nbsp;</th>
                      <th className="col-values">
                        <Units type="school"/>
                        <MarkGroup type="school" />
                      </th>
                    </tr>
                    </thead>

                    {graphData.map((section: any, index: any) => (
                        <tbody key={index}>
                        <TableRow section={section} index={index} />
                        </tbody>
                    ))}
                    <tfoot>
                    <tr className="legenda">
                      <td>&nbsp;</td>
                      <td className="col-legend">
                        <img src="/images/reports/chart-tabel.png" className="chart-tabel" alt="" />
                        <Units type="school"/>
                        <ChartNoteWrapperBig big={false} type="school" />
                      </td>
                    </tr>
                    </tfoot>
                  </table>
                </ScrollBar>
            </div>
          )}

          <div className="pagebreak"></div>
          {skillsData.length > 0 && (skillsData.map((skillData: any, index: any) => (
              <div key={index}>
              <SubcategorySection skillsData={skillsData[index]} key={index} type="school"/>
              </div>
          )))}
        </div>
      </div>
    </>
  );
};

export default Report;
