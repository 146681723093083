import React, { useState, useEffect } from "react";
import { getFaq } from "api/pages/faq";
import { useAuth } from "hooks/useAuth";
import PageTitle from "components/ui/PageTitle";
import Loading from "components/ui/Loading";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CustomAccordion from "components/ui/CustomAccordion";
// import { useAuth } from "hooks/useAuth";

const Faq = () => {
  const { update } = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [faqs, setFaqs] = useState<any[]>([]);

  useEffect(() => {
    setLoading(true);
    getFaq()
      .then((response) => {
        if (response.data.success) {
          // console.log("getFaq OK", response);
          setFaqs(response.data.data.values);
          setLoading(false);
        } else {
          update("error", { show: true, code: "A002" });
        }
      })
      .catch((err) => {
        // console.log("getFaq ERR", err);
        update("error", { show: true, code: "A001" });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box component="main">
      <PageTitle>Întrebări frecvente</PageTitle>
      {loading ? (
        <Box mt={3} display="flex" alignItems="center" width="100%">
          <Loading show={true} />
        </Box>
      ) : (
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          {faqs.map((faq, index) => (
            <Grid item md={12} lg={6} xl={4} key={index}>
              <Typography variant="h2" mb={2}>
                {faq.category}
              </Typography>
              <CustomAccordion data={faq.items} />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default Faq;
