import CreateTestCategories from "components/createTest/config/CreateTestCategories";
import CreateTestClassroom from "components/createTest/config/CreateTestClassroom";
import CreateTestConfiguration from "components/createTest/config/CreateTestConfiguration";
import CreateTestDifficulty from "components/createTest/config/CreateTestDifficulty";
import CreateTestExpire from "components/createTest/config/CreateTestExpire";
import CreateTestGrade from "components/createTest/config/CreateTestGrade";
import CreateTestIndividual from "components/createTest/config/CreateTestIndividual";
import CreateTestItemsNumber from "components/createTest/config/CreateTestItemsNumber";
import CreateTestStudents from "components/createTest/config/CreateTestStudents";
import CreateTestType from "components/createTest/config/CreateTestType";
import { ICreateTestTrainingConfig } from "components/createTest/_interfaces";
import CreateTestStartDate from "./config/CreateTestStartDate";

const CreateTestConfig = ({
  type,
  subjects,
  grades,
  createTestData,
  selectedStudents,
  setClassroomHandler,
  setSelectedStudentsHandler,
  setSelectAllStudentsHandler,
  handleSelectChange,
  handleInputChange,
  setSubjectHandler,
  setGradeHandler,
  setSelectedCategoriesHandler,
  handleTestStartDate,
  loading,
  errors,

}: ICreateTestTrainingConfig) => {
  return (
    <>
      <CreateTestClassroom
        errors={errors}
        setClassroomHandler={setClassroomHandler}
        classroom={createTestData.classroom}
      />
      {createTestData.classroom && (
        <CreateTestStudents
          errors={errors}
          loading={loading}
          users={createTestData.users}
          selectedStudents={selectedStudents}
          setSelectedStudentsHandler={setSelectedStudentsHandler}
          setSelectAllStudentsHandler={setSelectAllStudentsHandler}
        />
      )}
      {createTestData.users.some((el: any) => el.checked === true) && (
        <>
          <CreateTestConfiguration
            testConfig={createTestData.test_config.value}
            handleSelectChange={handleSelectChange}
            errors={errors}
          />

          {type === "training" && (
            <CreateTestIndividual
              errors={errors}
              individualTest={createTestData.individual_test.value}
              handleSelectChange={handleSelectChange}
            />
          )}
          <CreateTestStartDate errors={errors} startDate={createTestData.start_date} handleTestStartDate={handleTestStartDate}/>
          <CreateTestExpire expire={createTestData.expire} handleInputChange={handleInputChange} errors={errors} />
          {type === "training" && (
            <>
              <CreateTestItemsNumber
                errors={errors}
                itemsNumber={createTestData.items_number.value}
                handleSelectChange={handleSelectChange}
              />
            </>
          )}

          {type === "training" && createTestData.items_number.value && (
            <>
              <CreateTestDifficulty
                errors={errors}
                difficulty={createTestData.difficulty.value}
                handleSelectChange={handleSelectChange}
              />
            </>
          )}
          {((type === "school" && createTestData.expire) ||
            (type === "training" && createTestData.difficulty.value)) && (
            <CreateTestType
              loading={loading}
              errors={errors}
              subject={createTestData.subject.value}
              subjects={subjects}
              setSubjectHandler={setSubjectHandler}
            />
          )}
          {((createTestData.subject.value && createTestData.difficulty.value && type === "training") ||
            (createTestData.subject.value && type === "school" ) ||
            (type === "literacy" && createTestData.expire) ||
            (type === "digitalLiteracy" && createTestData.expire)) && (
            <CreateTestGrade
              loading={loading}
              errors={errors}
              grade={createTestData.grade.value}
              grades={grades}
              setGradeHandler={setGradeHandler}
            />
          )}
          {createTestData.grade.value && (
            <CreateTestCategories
              type={type}
              loading={loading}
              errors={errors}
              categories={createTestData.categories}
              setSelectedCategoriesHandler={setSelectedCategoriesHandler}
            />
          )}
          {(type === "bac" || type === "evaluate") && createTestData.expire && (
            <CreateTestType
              loading={loading}
              errors={errors}
              subject={createTestData.subject.value}
              subjects={subjects}
              setSubjectHandler={setSubjectHandler}
            />
          )}

          {createTestData.subject.value && (type === "bac" || type === "evaluate") && (
            <CreateTestCategories
              type={type}
              loading={loading}
              errors={errors}
              categories={createTestData.categories}
              setSelectedCategoriesHandler={setSelectedCategoriesHandler}
            />
          )}
        </>
      )}
    </>
  );
};

export default CreateTestConfig;
