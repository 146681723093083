export const activeTestsPrincipalsTableColumns = [
  {
    id: "test",
    label: "Test",
    numeric: false,
    disablePadding: false,
    sortable: true,
  },
    {
      id: "test_type",
      label: "Tip",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "test_grade",
      label: "Clasă test",
      numeric: false,
      disablePadding: false,
      noWrap: true,
      sortable: false,
    },
    {
      id: "owner_name",
      label: "Profesor",
      numeric: false,
      disablePadding: false,
      noWrap: true,
      sortable: true,
    },
    {
      id: "user_name",
      label: "Elev",
      numeric: false,
      noWrap: true,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "classroom_name",
      label: "Clasă",
      numeric: false,
      disablePadding: false,
      noWrap: true,
      sortable: true,
    },
    {
      id: "test_is_open",
      label: "Status",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "test_created_at",
      label: "Creat",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "test_expires_at",
      label: "Expiră",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "actions",
      label: "Acțiuni",
      numeric: true,
      disablePadding: true,
      noWrap: false,
    },
  ];

export const activeTestsTeachersTableColumns = [
  {
    id: "test",
    label: "Test",
    numeric: false,
    disablePadding: false,
    sortable: true,
  },
    {
      id: "test_type",
      label: "Tip",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "test_grade",
      label: "Clasă test",
      numeric: false,
      disablePadding: false,
      noWrap: true,
      sortable: false,
    },
    {
      id: "user_name",
      label: "Elev",
      numeric: false,
      noWrap: true,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "classroom_name",
      label: "Clasă",
      numeric: false,
      disablePadding: false,
      noWrap: true,
      sortable: true,
    },
    {
      id: "status",
      label: "Status",
      numeric: false,
      disablePadding: false,
    },
    {
      id: "test_created_at",
      label: "Creat",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "test_expires_at",
      label: "Expiră",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "actions",
      label: "Acțiuni",
      numeric: true,
      disablePadding: true,
      noWrap: false,
    },
  ];

export const historyTestsTrainingPrincipalsTableColumns = [
    {
      id: "test",
      label: "Test",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "test_type",
      label: "Tip",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "test_grade",
      label: "Clasă test",
      numeric: false,
      disablePadding: false,
      noWrap: true,
      sortable: false,
    },
    {
      id: "owner_name",
      label: "Profesor",
      numeric: false,
      disablePadding: false,
      noWrap: true,
      sortable: true,
    },
    {
      id: "user_name",
      label: "Elev",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "classroom_name",
      label: "Clasa",
      numeric: false,
      disablePadding: false,
      noWrap: true,
      sortable: true,
    },
    {
      id: "test_time",
      label: "Timp",
      numeric: false,
      disablePadding: false,
    },
    {
      id: "responses",
      label: "Răspunsuri",
      numeric: false,
      disablePadding: false,
    },
    {
      id: "test_started_at",
      label: "Dată",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "actions",
      label: "Acțiuni",
      numeric: true,
      disablePadding: true,
      noWrap: false,
    },
  ];

  export const historyTestsTrainingTeachersTableColumns = [
    {
      id: "test",
      label: "Test",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "test_type",
      label: "Tip",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "test_grade",
      label: "Clasă test",
      numeric: false,
      disablePadding: false,
      noWrap: true,
      sortable: false,
    },
    {
      id: "user_name",
      label: "Elev",
      numeric: false,
      noWrap: true,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "classroom_name",
      label: "Clasă",
      numeric: false,
      disablePadding: false,
      noWrap: true,
      sortable: true,
    },
    {
      id: "test_time",
      label: "Timp",
      numeric: false,
      disablePadding: false,
    },
    {
      id: "responses",
      label: "Răspunsuri",
      numeric: false,
      disablePadding: false,
    },
    {
      id: "test_started_at",
      label: "Dată",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "actions",
      label: "Acțiuni",
      numeric: true,
      disablePadding: true,
      noWrap: false,
    },
  ];

  export const historyTestsTrainingStudentsTableColumns = [
    {
      id: "test",
      label: "Test",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "test_type",
      label: "Tip",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "test_grade",
      label: "Clasă test",
      numeric: false,
      disablePadding: false,
      noWrap: true,
      sortable: false,
    },
    {
      id: "owner_name",
      label: "Profesor",
      numeric: false,
      noWrap: true,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "classroom_name",
      label: "Clasă",
      numeric: false,
      disablePadding: false,
      noWrap: true,
      sortable: true,
    },
    {
      id: "test_time",
      label: "Timp",
      numeric: false,
      disablePadding: false,
    },
    {
      id: "responses",
      label: "Răspunsuri",
      numeric: false,
      disablePadding: false,
    },
    {
      id: "test_started_at",
      label: "Dată",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "actions",
      label: "Acțiuni",
      numeric: true,
      disablePadding: true,
      noWrap: false,
    },
  ];