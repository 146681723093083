import React, {useEffect, useState} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import {APIPostClientLogin} from "../api/pages/clientLogin";
import {defaultUser, useAuth} from "../hooks/useAuth";
import {APIGetTest} from "../api/pages/test";
import {ITest} from "../components/test/_interfaces";
import Test from "../components/test/Test";

const TestClient = () => {
    const { id = "" } = useParams();
    const [searchParams] = useSearchParams();
    const { loginClient, update, user } = useAuth();
    const [test, setTest] = useState<ITest | null>(null);
    const token = searchParams.get("token");
    const domain = searchParams.get("domain");
    const [login, setLogin] = useState(false);

    useEffect(() => {
        update("loading", { show: true, modal: true });
        APIPostClientLogin({
            domain: domain,
            token: token,
        })
            .then((response) => {
                if (response.data.success && response.data.data?.token !== null) {
                    console.log("postLOGIN OK", response);
                    loginClient({
                        firstname: response.data.data.auth.first_name || "",
                        lastname: response.data.data.auth.last_name || "",
                        email: response.data.data.auth.email || "",
                        position: response.data.data.auth.position || "",
                        profilePhoto: response.data.data.auth.profile_photo || "",
                        token: `Bearer ${response.data.data.auth.token}`,
                        menu: response.data.data.auth.menu || [],
                        email_verified: response.data.data.auth.email_verified,
                        id: response.data.data.auth.id,
                        notifications: response.data.data.auth.notifications_unread,
                        schoolBoard: response.data.data.auth.has_school_board,
                        swap: response.data.data.auth.can_swap_profile,
                        client: true,
                    });
                    let tenantNew = response.data.data.domain;
                    delete tenantNew.label;
                    delete tenantNew.value;
                    update("tenant", tenantNew);
                    setLogin(true);
                } else {
                    update("loading", { show: false });
                    update("error", { show: true, code: "A002" });
                }
            })
            .catch((err) => {
                console.log("postLOGIN ERR", err);
                update("error", { show: true, code: "A001" });
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        update("loading", { show: true, modal: true });
        if(login) {
            APIGetTest({ test: id })
                .then((response) => {
                    if (response.data.success) {
                        console.log("APIGetTest OK", response);
                        setTest(response.data.data);
                        update("loading", { show: false });
                    } else {
                        if (response.data.code === 400) {
                            update("user", { theme: user.theme }, true);
                            update("tenant", defaultUser.tenant);
                            update("error", {
                                show: true,
                                title: "Sesiunea de testare a expirat",
                                message: "Contactează-ți profesorul coordonator pentru mai multe detalii.",
                                button: "ÎNCHIDE",
                                icon: "fat face-eyes-xmarks",
                                closable: false,
                            })} else {
                            update("error", { show: true, code: "A002" });
                            update("loading", { show: false });
                        }
                    }
                })
                .catch((err) => {
                    console.log("APIGetTest ERR");
                    update("error", { show: true, code: "A001" });
                    update("loading", { show: false });
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [login]);

    return test !== null ? <Test test={test} testFromClient={true}/> : null;

}

export default TestClient;