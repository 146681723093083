import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { testTrainingSchema } from "components/createTest/_schemas";
import { IFormCreateTestSchool, IProps } from "components/createTest/_interfaces";
import CreateTestConfig from "components/createTest/CreateTestConfig";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Loading from "components/ui/Loading";
import useCreateTestForm from "hooks/useCreateTestForm";
import { APICreateTest } from "api/pages/createTest";
import { useAuth } from "hooks/useAuth";
import React, {useEffect, useState} from "react";
import AddTestButtonLayout from "../AddTestButtonLayout";
import Icon from "../../ui/Icon";
import CustomButton from "../../ui/CustomButton";
import moment from "moment";

const CreateTestBac = ({ testType }: IProps) => {
  const { update } = useAuth();
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const {
    loading,
    grades,
    subjects,
    createTestData,
    selectedStudents,
    setClassroomHandler,
    setSelectedStudentsHandler,
    setSelectAllStudentsHandler,
    handleSelectChange,
    handleInputChange,
    setSubjectHandler,
    handleTestStartDate,
  } = useCreateTestForm(testType, "bac");

  const methods = useForm<IFormCreateTestSchool>({
    resolver: yupResolver(testTrainingSchema),
  });

  useEffect(() => {
    if(Object.keys(methods.formState.errors).length === 0) {
      setErrorMessage("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createTestData]);

  const onSubmit: SubmitHandler<IFormCreateTestSchool> = (data) => {
    let selectedStudents: number[] = [];
    let selectedCategories: number[] = [];
    selectedStudents = createTestData.users.filter((item: any) => item.checked === true).map(({ id }: any) => id);
    selectedCategories = createTestData.categories.filter((item: any) => item.checked === true).map(({ id }: any) => id);
    methods.clearErrors();
    setErrorMessage("");
    APICreateTest({
      grade: null,
      classroom_id: createTestData.classroom?.value,
      subcategories: selectedCategories,
      users: selectedStudents,
      test_config: createTestData.test_config.value,
      start_at: moment(createTestData.start_date).locale("ro").format("YYYY-MM-DD HH:mm:ss"),
      expire: createTestData.expire,
      test_mode: createTestData.subject.value,
      // item_type_id: createTestData.subject.value,
      item_type_id: 21,
    })
      .then((response) => {
        if (response.data.success) {
          setSuccessMessage(
            'Am pornit serviciul de generare a sesiunilor de testare pentru clasa selectată. Acestea le vor fi transmise elevilor pe e-mail, iar în pagina Teste bacalaureat, secțiunea Teste active, veți regăsi sesiunile generate în ordine descrescătoare.',
          );
          update("loading", { show: false });
        } else {
          update("loading", { show: false });
          update("error", { show: true, code: "A002" });
        }
      })
      .catch((err) => {
        update("loading", { show: false });
        if (err.response.status === 400 && err.response.data.data) {
          setErrorMessage("Unul sau mai multe câmpuri nu sunt valide.");
          let errors = err.response.data.data;
          if (errors.classroom) {
            methods.setError("classroom", { type: "server", message: errors.classroom[0] });
          }
          if (errors.users) {
            methods.setError("users", { type: "server", message: errors.users[0] });
          }
          if (errors.test_config) {
            methods.setError("test_config", { type: "server", message: errors.test_config[0] });
          }
          if (errors.start_at) {
            methods.setError("start_date", { type: "server", message: errors.start_at[0] });
          }
          if (errors.expire) {
            methods.setError("expire", { type: "server", message: errors.expire[0] });
          }
          if (errors.subject) {
            methods.setError("subject", { type: "server", message: errors.subject[0] });
          }
          if (errors.grade) {
            methods.setError("grade", { type: "server", message: errors.grade[0] });
          }
          if (errors.categories) {
            methods.setError("categories", { type: "server", message: errors.categories[0] });
          }
          if(errors.credits === 0) {
            setErrorMessage(errors.message);
          }
          update("loading", { show: false });
        } else {
          update("error", { show: true, code: "A001" });
        }
      });
  };

  const findMatch = (item: any, items: any) => JSON.stringify(items).includes(`"checked":${item}`);

  return (
    <>
      <Box width="100%">
        {successMessage ? (
          <Typography>{successMessage}</Typography>
        ) : (
          <>
            {loading.loading && loading.name === "step" ? (
              <>
                <Loading show={true} />
              </>
            ) : (
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
                  <Box mb={3}>
                    <CreateTestConfig
                      type="bac"
                      subjects={subjects}
                      grades={grades}
                      createTestData={createTestData}
                      selectedStudents={selectedStudents}
                      setClassroomHandler={setClassroomHandler}
                      setSelectedStudentsHandler={setSelectedStudentsHandler}
                      setSelectAllStudentsHandler={setSelectAllStudentsHandler}
                      handleSelectChange={handleSelectChange}
                      handleInputChange={handleInputChange}
                      setSubjectHandler={setSubjectHandler}
                      handleTestStartDate={handleTestStartDate}
                      loading={loading}
                      errors={methods.formState.errors}
                    />
                  </Box>
                  {findMatch(true, createTestData.categories) &&
                    createTestData.users.some((el: any) => el.checked === true) && (
                      <AddTestButtonLayout>
                        <Box display="flex" justifyContent="end">
                          {errorMessage && (
                              <Box display="flex" alignItems="flex-start" gap={0.5} mt={0.5} mr={1}>
                                <Box color="error.main">
                                  <Icon icon="circle-exclamation" fixedWidth={true} sx={{ fontSize: "1rem" }} />
                                </Box>
                                <Box flexGrow={1} mt={0.25}>
                                  <Typography variant="body2" color="error.main">{errorMessage}</Typography>
                                </Box>
                              </Box>
                          )}

                          <CustomButton buttonType="textIcon" type="submit" sx={{whiteSpace: "nowrap"}}>
                            Generează test
                          </CustomButton>
                        </Box>
                      </AddTestButtonLayout>
                    )}
                </form>
              </FormProvider>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default CreateTestBac;
