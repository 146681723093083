import {Document, Page, View, Font, Text, Image} from "@react-pdf/renderer";

import NatoSansThin from "../../../../fonts/NotoSans-Thin.ttf";
import NatoSansExtraLight from "../../../../fonts/NotoSans-ExtraLight.ttf";
import NatoSansLight from "../../../../fonts/NotoSans-Light.ttf";
import NatoSansRegular from "../../../../fonts/NotoSans-Regular.ttf";
import NatoSansMedium from "../../../../fonts/NotoSans-Medium.ttf";
import NatoSansSemiBold from "../../../../fonts/NotoSans-SemiBold.ttf";
import NatoSansBold from "../../../../fonts/NotoSans-Bold.ttf";
import NatoSansExtraBold from "../../../../fonts/NotoSans-ExtraBold.ttf";

import RubikLight from "../../../../fonts/Rubik-Light.ttf";
import RubikRegular from "../../../../fonts/Rubik-Regular.ttf";
import RubikMedium from "../../../../fonts/Rubik-Medium.ttf";
import RubikBold from "../../../../fonts/Rubik-Bold.ttf";
import RubikBlack from "../../../../fonts/Rubik-Black.ttf";

import {styles } from "../../StylesPdfReport";
import CoverPage from "../../components/pdf/CoverPage";
import AboutSection from "./AboutSection";
import Chart from "../../components/pdf/Chart";
import ChartSubsection from "./ChartSubsection";
import Units from "../../components/pdf/Units";
import MarkGroup from "../../components/pdf/MarkGroup";
import TableRow from "../../components/pdf/TableRow";
import ChartNoteWrapperBig from "../../components/pdf/ChartNoteWrapperBig";

Font.register({
    family: "NotoSans",
    format: "truetype",
    fonts: [
        { src: NatoSansThin, fontWeight: 100 },
        { src: NatoSansExtraLight, fontWeight: 200 },
        { src: NatoSansLight, fontWeight: 300 },
        { src: NatoSansRegular, fontWeight: 400 },
        { src: NatoSansMedium, fontWeight: 500 },
        { src: NatoSansSemiBold, fontWeight: 600 },
        { src: NatoSansBold, fontWeight: 700 },
        { src: NatoSansExtraBold, fontWeight: 800 },
    ],
});

Font.register({
    family: "Rubik",
    format: "truetype",
    fonts: [
        { src: RubikLight, fontWeight: 300 },
        { src: RubikRegular, fontWeight: 400 },
        { src: RubikMedium, fontWeight: 500 },
        { src: RubikBold, fontWeight: 700 },
        { src: RubikBlack, fontWeight: 800 },
    ],
});

const Report = ({ reportData, gradeLevels, graphData }: any) => {

    return (
        <Document>
            <Page wrap size="A4" style={styles.body}>
                <View style={styles.coverBg}>
                    <CoverPage coverImage="/images/reports/cover-literatie.png" type="literacy">
                        <View style={styles.titleCover}>
                            <Text style={styles.h1Peach}>Testul de literație</Text>
                            <Text style={styles.h2Peach}>
                                Sesiune #{reportData.test.id}
                            </Text>
                            <Text style={styles.h1Peach}>Indice de literație {reportData.score}</Text>
                        </View>
                    </CoverPage>
                    <View style={styles.wrapperCover}>
                        <AboutSection />
                        <View style={styles.sectionAbout}>
                            <Text style={[styles.h1, { color: "#002D96" }]}>Indicele de literație: {reportData.score} din 100</Text>
                            <Chart
                                testScore={reportData.score}
                                type="literacy"
                            />
                        </View>
                        {graphData.length > 0 &&
                            <>
                                <View break style={styles.sectionAbout} >
                                    <Text style={styles.h1}>Profilul competențelor evaluate</Text>
                                    <View style={styles.tableNote}>
                                        <View style={{ position: "relative" }}>
                                            <MarkGroup type="literacy" />
                                            <View style={styles.gradesRow}>
                                                <View style={styles.colChapter}></View>
                                                <View style={[styles.colValues, { paddingBottom: "20pt" }]}>
                                                    <Units type="literacy"/>
                                                </View>
                                            </View>
                                            {graphData.map((section: any, index: any) => (
                                                <View key={index} >
                                                    <TableRow section={section} index={index} />
                                                </View>
                                            ))}
                                        </View>
                                        <View style={[styles.gradesRow, { border: "none", marginTop: "7pt" }]}>
                                            <View style={styles.colChapter}></View>
                                            <View style={styles.colValues}>
                                                <View>
                                                    <Image src="/images/reports/chart-tabel-literatie.png" style={styles.chartTable} />
                                                    <Units type="literacy"/>
                                                    <ChartNoteWrapperBig big={false} type="literacy" />
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </>
                        }
                        {gradeLevels.length > 0 && (
                            <View style={styles.sectionAbout}>
                                <Text style={[styles.h1, { color: "#002D96" }]}>Interpretarea indicelui de literație</Text>
                                <Text style={styles.p}>Mai jos este prezentată semnificația indicelui de literație rezultat în urma evaluării elevilor, în funcție de
                                    încadrarea scorului la nivelul de alfabetizare subdezvoltată (scor între 0 și 25), nivelul funcțional al
                                    alfabetizării (26-80) sau nivelul înalt al alfabetizării (81-100).</Text>
                                {gradeLevels.map((gradeLevel: any, index: any) => (
                                        <ChartSubsection gradeLevel={gradeLevel} score={reportData.score} index={index} type="literacy"/>))}
                            </View>

                        )}
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default Report;
