export const activeTestsPrincipalsTableColumns = [
  {
    id: "test",
    label: "Test",
    numeric: false,
    disablePadding: false,
    sortable: true,
  },
    {
      id: "test_type",
      label: "Tip",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "test_grade",
      label: "Clasă test",
      numeric: false,
      disablePadding: false,
      noWrap: true,
      sortable: false,
    },
    {
      id: "owner_name",
      label: "Profesor",
      numeric: false,
      disablePadding: false,
      noWrap: true,
      sortable: true,
    },
    {
      id: "user_name",
      label: "Elev",
      numeric: false,
      disablePadding: false,
      noWrap: true,
      sortable: true,
    },
    {
      id: "classroom_name",
      label: "Clasă",
      numeric: false,
      disablePadding: false,
      noWrap: true,
      sortable: true,
    },
    {
      id: "test_is_open",
      label: "Status",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "test_created_at",
      label: "Creat",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "test_closing_date",
      label: "Expiră",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "actions",
      label: "Acțiuni",
      numeric: true,
      disablePadding: true,
      noWrap: false,
    },
  ];

export const activeTestsTeachersTableColumns = [
  {
    id: "test",
    label: "Test",
    numeric: false,
    disablePadding: false,
    sortable: true,
  },
    {
      id: "test_type",
      label: "Tip",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "test_grade",
      label: "Clasă test",
      numeric: false,
      disablePadding: false,
      noWrap: true,
      sortable: false,
    },
    {
      id: "user_name",
      label: "Elev",
      numeric: false,
      disablePadding: false,
      noWrap: true,
      sortable: true,
    },
    {
      id: "classroom_name",
      label: "Clasa",
      numeric: false,
      disablePadding: false,
      noWrap: true,
      sortable: true,
    },
    {
      id: "status",
      label: "Status",
      numeric: false,
      disablePadding: false,
    },
    {
      id: "test_created_at",
      label: "Creat",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "test_closing_date",
      label: "Expiră",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "actions",
      label: "Acțiuni",
      numeric: true,
      disablePadding: false,
      noWrap: false,
    },
  ];

  export const activeTestsStudentsTableColumns = [
    {
      id: "test",
      label: "Test",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "test_type",
      label: "Tip",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "test_grade",
      label: "Clasă test",
      numeric: false,
      disablePadding: false,
      noWrap: true,
      sortable: false,
    },
    {
      id: "owner_name",
      label: "Profesor",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "classroom_name",
      label: "Clasă",
      numeric: false,
      disablePadding: false,
      noWrap: true,
      sortable: true,
    },
    {
      id: "status",
      label: "Status",
      numeric: false,
      disablePadding: false,
    },
    {
      id: "test_created_at",
      label: "Creat",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "test_closing_date",
      label: "Expiră",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "actions",
      label: "Acțiuni",
      numeric: true,
      disablePadding: true,
      noWrap: false,
    },
  ];

  export const historyTestsPrincipalsTableColumns = [
    {
      id: "test",
      label: "Test",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "test_type",
      label: "Tip",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "test_grade",
      label: "Clasă test",
      numeric: false,
      disablePadding: false,
      noWrap: true,
      sortable: false,
    },
    {
      id: "owner_name",
      label: "Profesor",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "user_name",
      label: "Elev",
      numeric: false,
      disablePadding: false,
      noWrap: true,
      sortable: true,
    },
    {
      id: "classroom_name",
      label: "Clasă",
      numeric: false,
      disablePadding: false,
      noWrap: true,
      sortable: true,
    },
    {
      id: "test_score",
      label: "Scor",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "test_time",
      label: "Timp",
      numeric: false,
      disablePadding: false,
    },
    {
      id: "responses",
      label: "Răspunsuri",
      numeric: false,
      disablePadding: false,
    },
    {
      id: "test_started_at",
      label: "Data",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "actions",
      label: "Acțiuni",
      numeric: true,
      disablePadding: true,
      noWrap: false,
    },
  ];

  export const historyTestsTeachersTableColumns = [
    {
      id: "test",
      label: "Test",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "test_type",
      label: "Tip",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "test_grade",
      label: "Clasă test",
      numeric: false,
      disablePadding: false,
      noWrap: true,
      sortable: false,
    },
    {
      id: "user_name",
      label: "Elev",
      numeric: false,
      disablePadding: false,
      noWrap: true,
      sortable: true,
    },
    {
      id: "classroom_name",
      label: "Clasă",
      numeric: false,
      disablePadding: false,
      noWrap: true,
      sortable: true,
    },
    {
      id: "test_score",
      label: "Scor",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "test_time",
      label: "Timp",
      numeric: false,
      disablePadding: false,
    },
    {
      id: "responses",
      label: "Răspunsuri",
      numeric: false,
      disablePadding: false,
    },
    {
      id: "test_started_at",
      label: "Dată",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "actions",
      label: "Acțiuni",
      numeric: true,
      disablePadding: true,
      noWrap: false,
    },
  ];

  export const historyTestsStudentsTableColumns = [
    {
      id: "test",
      label: "Test",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "test_type",
      label: "Tip",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "test_grade",
      label: "Clasă test",
      numeric: false,
      disablePadding: false,
      noWrap: true,
      sortable: false,
    },
    {
      id: "owner_name",
      label: "Profesor",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "classroom_name",
      label: "Clasă",
      numeric: false,
      disablePadding: false,
      noWrap: true,
      sortable: true,
    },
    {
      id: "test_score",
      label: "Scor",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "test_time",
      label: "Timp",
      numeric: false,
      disablePadding: false,
    },
    {
      id: "responses",
      label: "Răspunsuri",
      numeric: false,
      disablePadding: false,
    },
    {
      id: "test_started_at",
      label: "Dată",
      numeric: false,
      disablePadding: false,
      sortable: true,
    },
    {
      id: "actions",
      label: "Acțiuni",
      numeric: true,
      disablePadding: true,
      noWrap: false,
    },
  ];
