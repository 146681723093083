import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormField from "components/form/FormField";
import {useNavigate} from "react-router-dom";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAuth } from "hooks/useAuth";
import { APIPostRegisterTeacherSchools } from "api/pages/login";
import ErrorsList from "components/ui/ErrorsList";
import {APIGetSchools} from "../../api/pages/schools";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

interface IFormRegister {
    school: {label: string, value: number, slug: string};
    code: string;
    last_name: string;
    first_name: string;
    email: string;
    password?: string;
    confirm_password?: string;
}

const schema = yup
    .object({
    })
    .required();

const RegisterSchoolTeachersForm = () => {
    const { update } = useAuth();
    const [errorMessage, setErrorMessage] = useState<string>("");
    const navigate = useNavigate();

    const methods = useForm<IFormRegister>({
        resolver: yupResolver(schema),
    });

    const onSubmit: SubmitHandler<IFormRegister> = (data) => {
        const school = data.school ? data.school.slug : null;
        update("loading", { show: true, modal: true });

        APIPostRegisterTeacherSchools({
            school: school,
            code: data.code,
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            password: data.password,
            confirm_password: data.confirm_password,
        })
            .then((response) => {
                if (response.data.success) {
                    update("loading", { show: false });
                    update("error", {
                        show: true,
                        closable: true,
                        title: "Cererea de înregistrare este în curs de procesare",
                        message: "În curând veți primi un e-mail de verificare a contului.",
                        icon: "fat face-smile",
                        button: "ÎNCHIDE",
                        returnFunction: () => {
                        navigate("/");
                    },
                    });
                    setErrorMessage("");
                } else {
                    update("loading", { show: false });
                    update("error", { show: true, code: "A002" });
                }
            })
            .catch((err) => {
                console.log("postRegister ERR", err);
                if (err.response.status === 400) {
                    let errors = err.response.data.data;
                    if (errors.school) {
                        methods.setError("school", { type: "server", message: errors.school[0] });
                    }
                    if (errors.code) {
                        methods.setError("code", { type: "server", message: errors.code[0] });
                    }
                    if (errors.last_name) {
                        methods.setError("last_name", { type: "server", message: errors.last_name[0] });
                    }
                    if (errors.first_name) {
                        methods.setError("first_name", { type: "server", message: errors.first_name[0] });
                    }
                    if (errors.email) {
                        methods.setError("email", { type: "server", message: errors.email[0] });
                    }
                    if (errors.password) {
                        methods.setError("password", { type: "server", message: errors.password[0] });
                    }
                    if (errors.confirm_password) {
                        methods.setError("confirm_password", { type: "server", message: errors.confirm_password[0] });
                    }
                    update("loading", { show: false });
                } else {
                    update("error", { show: true, code: "A001" });
                }
            });
    };

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
                    <Box mb={2}>
                        <Box py={1}>
                            <Typography sx={{color: "primary.dark", fontSize: "1.1rem",}}>
                                Caută școala ta
                            </Typography>
                            <FormField
                                name="school"
                                type="autocomplete_api"
                                label="Caută școala..."
                                optionsAPI={APIGetSchools}
                                defaultValue={null}
                                multiple={false}
                                filterOptions={(x: any) => x}
                                errorMessage={methods.formState.errors.school?.message}
                                error={!!methods.formState.errors.school?.message}
                                autoFocus={true}
                            />
                        </Box>
                        <Box py={1}>
                            <Typography sx={{color: "primary.dark", fontSize: "1.1rem",}}>
                                Introdu codul primit sau serial number al dispozitivului (regăsit pe lateralul echipamentului)
                            </Typography>
                            <FormField
                                name="code"
                                type="text"
                                defaultValue=""
                                label="Cod"
                                error={!!methods.formState.errors.code?.message}
                                errorMessage={methods.formState.errors.code?.message}
                            />
                        </Box>
                    </Box>
                    <Divider sx={{ borderColor: "primary.main", marginBottom: 1.5, marginTop: 1 }} />
                    <Box py={1}>
                        <FormField
                            name="first_name"
                            type="text"
                            defaultValue=""
                            label="Prenume"
                            error={!!methods.formState.errors.first_name?.message}
                            errorMessage={methods.formState.errors.first_name?.message}
                        />
                    </Box>
                    <Box py={1}>
                        <FormField
                            name="last_name"
                            type="text"
                            defaultValue=""
                            label="Nume"
                            error={!!methods.formState.errors.last_name?.message}
                            errorMessage={methods.formState.errors.last_name?.message}
                        />
                    </Box>
                    <Box py={1}>
                        <FormField
                            name="email"
                            type="text"
                            defaultValue=""
                            label="Adresa de e-mail"
                            autoComplete="email"
                            error={!!methods.formState.errors.email?.message}
                            errorMessage={methods.formState.errors.email?.message}
                        />
                    </Box>
                    <Box py={1}>
                        <FormField
                            name="password"
                            type="password"
                            defaultValue=""
                            label="Parola"
                            autoComplete="password"
                            error={!!methods.formState.errors.password?.message}
                            errorMessage={methods.formState.errors.password?.message}
                        />
                    </Box>
                    <Box py={1}>
                        <FormField
                            name="confirm_password"
                            type="password"
                            label="Confirmă parola"
                            error={!!methods.formState.errors.confirm_password?.message}
                            errorMessage={methods.formState.errors.confirm_password?.message}
                        />
                    </Box>
                    <Box
                        sx={{
                            mt: 1,
                            mb: 3,
                        }}
                    >
                        <Button type="submit" variant="contained">
                            Înregistrare
                        </Button>
                        <ErrorsList show={errorMessage !== ""}>{errorMessage}</ErrorsList>
                    </Box>
                </form>
            </FormProvider>
        </>
    );
};

export default RegisterSchoolTeachersForm;
