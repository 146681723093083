import Box from "@mui/material/Box";
import PageTitle from "../components/ui/PageTitle";
import React from "react";
import BoxContent from "../components/ui/BoxContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const Contact = () => {
    return (
        <Box component="main">
            <PageTitle>
                Contact
            </PageTitle>
            <BoxContent>
                <Grid container spacing={1.5} alignItems="center">
                    <Grid item xs={12} sm={3} md={2}>
                        <Typography variant="body1"><b>Telefon:</b></Typography>
                    </Grid>
                    <Grid item xs={12} sm={9} md={10}>
                        <Typography variant="body1">0730.819.421 (10:00 - 17:00)</Typography>
                    </Grid>
                    <Grid item xs={12} sm={3} md={2}>
                        <Typography variant="body1"><b>Program:</b></Typography>
                    </Grid>
                    <Grid item xs={12} sm={9} md={10}>
                        <Typography>10:00 - 17:00, L-V</Typography>
                    </Grid>
                    <Grid item xs={12} sm={3} md={2}>
                        <Typography variant="body1"><b>Adresa:</b></Typography>
                    </Grid>
                    <Grid item xs={12} sm={9} md={10}>
                        <Typography>Str. Icoanei nr. 29A, București, Sector 2, Cod poștal 020452, România</Typography>
                    </Grid>
                </Grid>
            </BoxContent>
        </Box>
    )
}

export default Contact;