import React, { useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Box from "@mui/material/Box";
import ErrorsList from "components/ui/ErrorsList";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Icon from "components/ui/Icon";

const StyledBox = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(1.5),
  position: "relative",
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "white",
}));

interface Props {
  name: string;
  defaultValue?: string;
  errorMessage?: string;
  label?: string;
  updateFileCb: (file: File | null, fileUrl: string) => void;
  error?: boolean;
  fileType?: "image" | "xls";
}

const UploadButton = ({ name, defaultValue = "", label, errorMessage = "", updateFileCb, error = false, fileType = "image" }: Props) => {
  const methods = useFormContext();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileUrl, setFileUrl] = useState<string>("");
  const [fileError, setFileError] = useState<string>("");
  const fileInputField = useRef<HTMLInputElement>(null);
  const fileTypes = ["xlsx", "xls"].map(function(x) {return "." + x;}).join(",");

  const callUpdateFilesCb = (file: File | null, fileUrl: string) => {
    updateFileCb(file, fileUrl);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      console.log(errorMessage, error)
    const fileList = e.target.files;
      methods.clearErrors("file");
    if (!fileList) return;

    if(fileType === "image" || fileType === "xls") {
        let updatedFile = fileList[0];
        setSelectedFile(updatedFile);
        let updatedFileUrl = URL.createObjectURL(updatedFile);
        setFileUrl(updatedFileUrl);
        setFileError("");
        callUpdateFilesCb(updatedFile, updatedFileUrl);
    } else {
            setSelectedFile(null);
            setFileError("Tipul de fișier nu este permis");
        }

  };

  // const handleFileDelete = (e: React.MouseEvent) => {
  //   let updatedFile = null;
  //   let updatedFileUrl = "";
  //   setSelectedFile(updatedFile);
  //   setFileUrl(updatedFileUrl);
  //   setFileError("");
  //   callUpdateFilesCb(updatedFile, updatedFileUrl);
  // };

  // @ts-ignore
    return (
    <>
      <Box>
        <StyledBox sx={{border: error? "1px solid #9D0000" : "none"}}>
          <Controller
            name={name}
            control={methods.control}
            defaultValue={defaultValue}
            render={({ field }) => (
              <Box sx={{ textAlign: "center" }}>
                <Icon icon="fat cloud-arrow-up" fixedWidth={true} sx={{ fontSize: "3rem", color: error ? "error.main" : "primary.light"}} />
                <Typography variant="h5" sx={{color: error ? "error.main" : "secondary.light"}}>
                    {fileType === "image" ? "Încarcă o imagine" : "Încarcă un fișier"}
                </Typography>
                {/* <StyledButton
                  onClick={handleUploadBtnClick}
                  variant="outlined"
                  startIcon={<FontAwesomeIcon style={{ fontSize: "1rem" }} icon={solid("image")} />}
                >
                  Încarcă o imagine
                </StyledButton> */}
                <input
                  style={{
                    fontSize: "18px",
                    display: "block",
                    width: " 100%",
                    border: "none",
                    textTransform: "none",
                    position: "absolute",
                    cursor: "pointer",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    opacity: 0,
                  }}
                  {...field}
                //@ts-ignore
                  accept={fileType === "image" ? "image/*" : fileTypes}
                  id="file"
                  type="file"
                  onChange={handleFileChange}
                  ref={fileInputField}
                />
              </Box>
            )}
          />
        </StyledBox>
          <Typography variant="body2">Tipuri de fișiere permise: {fileType === "image" ? "jpg, jpeg, png." : "xlsx."} Fișierul nu poate depăși 5MB. </Typography>
        <ErrorsList show={fileError !== ""}>{fileError}</ErrorsList>
        <ErrorsList show={errorMessage !== ""}>{errorMessage}</ErrorsList>
      </Box>
    </>
  );
};

export default UploadButton;
