import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormField from "components/form/FormField";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAuth } from "hooks/useAuth";
import { APIPostRegister } from "api/pages/login";
import ErrorsList from "components/ui/ErrorsList";

interface IFormRegister {
  last_name: string;
  first_name: string;
  email: string;
  password?: string;
  confirm_password?: string;
}

const schema = yup
  .object({
  })
  .required();

const RegisterForm = () => {
  const { register, update } = useAuth();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const methods = useForm<IFormRegister>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IFormRegister> = (data) => {
    // console.log(data);
    update("loading", { show: true, modal: true });

    APIPostRegister({
      domain: "teachers",
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      password: data.password,
      confirm_password: data.confirm_password,
    })
      .then((response) => {
        if (response.data.success && response.data.data?.register.token !== null) {
          // console.log("postRegister OK", response);
          register({
            firstname: response.data.data.register.first_name || "",
            lastname: response.data.data.register.last_name || "",
            email: response.data.data.register.email || "",
            position: response.data.data.register.position || "",
            profilePhoto: response.data.data.register.profile_photo || "",
            token: `Bearer ${response.data.data.register.token}`,
            menu: response.data.data.register.menu || [],
            email_verified: response.data.data.register.email_verified,
          });
          let tenantNew = response.data.data.domain;
          delete tenantNew.label;
          delete tenantNew.value;
          update("tenant", tenantNew);
          update("loading", { show: false });
          setErrorMessage("");
        } else {
          update("loading", { show: false });
          update("error", { show: true, code: "A002" });
        }
      })
      .catch((err) => {
        console.log("postRegister ERR", err);
        if (err.response.status === 400) {
          let errors = err.response.data.data;
          if (errors.last_name) {
            methods.setError("last_name", { type: "server", message: errors.last_name[0] });
          }
          if (errors.first_name) {
            methods.setError("first_name", { type: "server", message: errors.first_name[0] });
          }
          if (errors.email) {
            methods.setError("email", { type: "server", message: errors.email[0] });
          }
          if (errors.password) {
            methods.setError("password", { type: "server", message: errors.password[0] });
          }
          if (errors.confirm_password) {
            methods.setError("confirm_password", { type: "server", message: errors.confirm_password[0] });
          }
          update("loading", { show: false });
        } else {
          update("error", { show: true, code: "A001" });
        }
      });
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
          <Box py={1}>
            <FormField
              name="first_name"
              type="text"
              defaultValue=""
              label="Prenume"
              autoFocus={true}
              error={!!methods.formState.errors.first_name?.message}
              errorMessage={methods.formState.errors.first_name?.message}
            />
          </Box>
          <Box py={1}>
            <FormField
              name="last_name"
              type="text"
              defaultValue=""
              label="Nume"
              error={!!methods.formState.errors.last_name?.message}
              errorMessage={methods.formState.errors.last_name?.message}
            />
          </Box>
          <Box py={1}>
            <FormField
              name="email"
              type="text"
              defaultValue=""
              label="Adresa de e-mail"
              autoComplete="email"
              error={!!methods.formState.errors.email?.message}
              errorMessage={methods.formState.errors.email?.message}
            />
          </Box>
          <Box py={1}>
            <FormField
              name="password"
              type="password"
              defaultValue=""
              label="Parola"
              autoComplete="password"
              error={!!methods.formState.errors.password?.message}
              errorMessage={methods.formState.errors.password?.message}
            />
          </Box>
          <Box py={1}>
            <FormField
              name="confirm_password"
              type="password"
              label="Confirmă parola"
              error={!!methods.formState.errors.confirm_password?.message}
              errorMessage={methods.formState.errors.confirm_password?.message}
            />
          </Box>
          <Box
            sx={{
              mt: 1,
              mb: 3,
            }}
          >
            <Button type="submit" variant="contained">
              Înregistrare
            </Button>
            <ErrorsList show={errorMessage !== ""}>{errorMessage}</ErrorsList>
          </Box>
        </form>
      </FormProvider>
    </>
  );
};

export default RegisterForm;
