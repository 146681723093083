import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import BoxContent from "components/ui/BoxContent";
import PageTitle from "components/ui/PageTitle";
import { useAuth } from "hooks/useAuth";
import FormField from "components/form/FormField";
import { APIPostFeedback } from "api/pages/feedback";
import UploadButton from "components/form/UploadButton";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormLabelBox from "components/ui/FormLabelBox";
import FormFieldBox from "components/ui/FormFieldBox";
import CustomButton from "../components/ui/CustomButton";

interface IFormFeedback {
  subject: string;
  message: string;
  phone: string;
  file?: string;
}

const schema = yup
  .object({
  })
  .required();

const Feedback = () => {
  const { update } = useAuth();

  const methods = useForm<IFormFeedback>({
    resolver: yupResolver(schema),
  });

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileUrl, setFileUrl] = useState<string>("");

  const updateUploadedFile = (file: File | null, selectedFileUrl: string) => {
    setSelectedFile(file);
    setFileUrl(selectedFileUrl);
  };

  const handleFileDelete = (e: React.MouseEvent) => {
    setSelectedFile(null);
  };

  const onSubmit: SubmitHandler<IFormFeedback> = (data) => {
    // setError("");

    const formData = new FormData();
    formData.append("subject", data.subject);
    formData.append("phone", data.phone);
    formData.append("content", data.message);
    selectedFile && formData.append("file", selectedFile);

    APIPostFeedback(formData)
      .then((response) => {
        if (response.data.success) {
          console.log("postFeedback OK", response);
          // logic after update
          // setSuccessMessage("Mesajul a fost trimis cu succes. Un reprezentant Brio îți va răspunde în cel mai scurt timp posibil.");
          update("error", {
            show: true,
            message: "Un reprezentant Brio îți va răspunde în cel mai scurt timp posibil.",
            title: "Mesajul a fost trimis cu succes",
            icon: "fat envelope",
            closable: true,
            button: "ÎNCHIDE",
          });
          methods.reset({
            subject: "",
            message: "",
            phone: "",
          });
          setSelectedFile(null);
        } else {
          update("error", { show: true, code: "A002" });
        }
      })
      .catch((err) => {
        console.log("postFeedback ERR", err);
        if (err.response.status === 400) {
          let errors = err.response.data.data;
          // console.log(errors.file[0]);
          if (errors.subject) {
            methods.setError("subject", { type: "server", message: errors.subject[0] });
          }
          if (errors.content) {
            methods.setError("message", { type: "server", message: errors.content[0] });
          }
          if (errors.phone) {
            methods.setError("phone", { type: "server", message: errors.phone[0] });
          }
          if (errors.file) {
            methods.setError("file", { type: "server", message: errors.file[0] });
            setSelectedFile(null);
          }
        } else {
          update("error", { show: true, code: "A001" });
        }
      });
  };

  return (
    <Box component="main">
      <PageTitle back={true}>Feedback</PageTitle>

      <BoxContent>
        <Box width="100%">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
              <Grid container spacing={1.5} alignItems="center">
                <FormLabelBox title="Subiect:" />
                <FormFieldBox>
                  <FormField
                    name="subject"
                    type="text"
                    label="Subiect"
                    defaultValue=""
                    autoFocus={true}
                    errorMessage={methods.formState.errors.subject?.message}
                    error={!!methods.formState.errors.subject?.message}
                  />
                </FormFieldBox>

                <FormLabelBox title="Mesaj:" />
                <FormFieldBox>
                  <FormField
                    name="message"
                    type="text"
                    defaultValue=""
                    label="Mesaj"
                    errorMessage={methods.formState.errors.message?.message}
                    error={!!methods.formState.errors.message?.message}
                  />
                </FormFieldBox>

                <FormLabelBox title="Telefon:" />
                <FormFieldBox>
                  <FormField
                    name="phone"
                    type="text"
                    label="Telefon"
                    defaultValue=""
                    errorMessage={methods.formState.errors.phone?.message}
                    error={!!methods.formState.errors.phone?.message}
                  />
                </FormFieldBox>

                <FormLabelBox title="Imagine:" />
                <FormFieldBox>
                  <>
                    <UploadButton
                      name="file"
                      label="Imagine"
                      defaultValue=""
                      updateFileCb={updateUploadedFile}
                      errorMessage={methods.formState.errors.file?.message}
                      error={!!methods.formState.errors.file?.message}
                      fileType="image"
                    />
                  </>
                </FormFieldBox>
                {fileUrl !== "" && selectedFile && (
                  <>
                    <FormLabelBox />
                    <FormFieldBox>
                      <Badge
                        sx={{ backgroundColor: "transparent" }}
                        badgeContent={
                          <IconButton aria-label="delete" onClick={handleFileDelete}>
                            <FontAwesomeIcon style={{ fontSize: "1rem", color: "#9D0000" }} icon={"xmark"} />
                          </IconButton>
                        }
                      >
                        <img src={fileUrl} alt="" height="auto" width="200px" />
                      </Badge>
                    </FormFieldBox>
                  </>
                )}
                <FormLabelBox />
                <FormFieldBox>
                  <CustomButton buttonType="textIcon" bgcolor="dark" submit={true}>
                    TRIMITE FEEDBACK
                  </CustomButton>
                </FormFieldBox>
              </Grid>
            </form>
          </FormProvider>
        </Box>
      </BoxContent>
    </Box>
  );
};

export default Feedback;
