import React from "react";
import { View, Text } from "@react-pdf/renderer";
import Html from "react-pdf-html";
import {styles } from "../../StylesPdfReport";
import {markedLevelBorder, ReportLeftBottom, ReportSegmentsImage} from "../../../../utils/reportsFunctions";
import Chart from "./Chart";
import ChartSubsection from "../pdf/ChartSubsection";

const stylesheet = {
    html: {
        fontSize: "9pt",
    },
    p: {
        fontFamily: "NotoSans",
        fontWeight: 400,
        fontSize: "9pt",
        textTransform: undefined,
    },
    ul: {
        fontFamily: "NotoSans",
        fontWeight: 400,
        fontSize: "9pt",
        textTransform: undefined,
    },
    li: {
        fontFamily: "NotoSans",
        fontWeight: 400,
        fontSize: "9pt",
        textTransform: undefined,
    },
};

const SubcategorySection = ({ skillsData, type, small= false }: any) => {
    const left = ReportLeftBottom(skillsData.score, type)[0];
    let bottom = ReportLeftBottom(skillsData.score, type)[1];
    const segmentsImage = ReportSegmentsImage(skillsData.score, type);
    const color = markedLevelBorder(skillsData.score, skillsData.level, type)[1];
    let newDescription = "";
    if (skillsData.description && !Array.isArray(skillsData.description)) {
        const regex = /style="(.*?)"/gm;
        const description = skillsData.description;
        const dscription1 = description.replace("<i>", "<b>").replace("</i>", "</b>");
        const dscription2 = dscription1.replace(regex, "");
        newDescription = dscription2.replace("<em>", "<b>").replace("</em>", "</b>");
    }

    return (
        <>
            <View wrap={false}>
                <Text style={[styles.h1, { marginTop: "11pt" }]}>
                    {skillsData.numbering1}. {skillsData.subcategory.category.name}
                </Text>
                <Text style={styles.h2}>
                    {skillsData.numbering1}. {skillsData.numbering2}. {skillsData.subcategory.name}
                </Text>
                <Chart
                    left={left}
                    scoreDescription={skillsData.score_description}
                    bottom={bottom}
                    testScore={skillsData.score}
                    segmentsImage={segmentsImage}
                    type={type}
                />
            </View>
            {skillsData.description && Array.isArray(skillsData.description) ? (
                <>
                    <View>
                        <Text style={styles.h3}>Scor BRIO {skillsData.score}</Text>
                        <View style={styles.subsectionContent}>
                            <Text style={styles.p}>{skillsData.description[0]}</Text>
                            {skillsData.description.map(
                                (description: any, index: any) => (index !== 0 && <Text key={index}>{description}</Text> ),
                            )}
                        </View>
                    </View>
                    <View>
                        <View style={styles.title}>
                            <Text style={{ marginRight: "3pt", marginTop: "20pt" }}>NIVELUL CORESPUNZĂTOR SCORULUI {skillsData.score}:</Text>
                            <View style={{ width: "10px", height: "10px", margin: 0, bottom: "-3pt", position: "relative", marginTop: "20pt"}}>
                                <View style={{ backgroundColor: color, width: 10, height: 10 }}></View>
                            </View>
                        </View>
                        <View style={styles.subsectionContent}>
                            {skillsData.score_description.map((scoreDescriptions: any, index: any) => (
                                <View key={index}>
                                    {scoreDescriptions.level.toLowerCase() === skillsData.level && (
                                        <Html style={{ fontSize: 9 }} stylesheet={stylesheet}>
                                            {scoreDescriptions.description}
                                        </Html>
                                    )}
                                </View>
                            ))}
                        </View>
                    </View>

                    {skillsData.score_description.map((scoreDescriptions: any, index: any) => (
                        <>
                            {scoreDescriptions.study_time !== null && skillsData.score < 95 && (
                                <Text key={index} style={[styles.h3, { marginTop: "20pt" }]}>
                                    Timp de studiu necesar pentru a ajunge la nivelul următor de dezvoltare a competenței:{" "}
                                    {scoreDescriptions.study_time} ore
                                </Text>
                            )}
                        </>
                    ))}
                </>
            ) : (
                <Html style={{ fontSize: 9 }} stylesheet={stylesheet}>
                    {newDescription}
                </Html>
            )}
                <>
                    <Text style={styles.h2}>Scala de evaluare - {skillsData.subcategory.name}</Text>
                    {skillsData.score_description.map((gradeLevel: any, index: any) => (

                        <View key={index}>
                            <ChartSubsection gradeLevel={gradeLevel} score={skillsData.score} index={index} type={type} small={small}/>
                        </View>
                    ))}
                </>
        </>
    );
};

export default SubcategorySection;
