import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import BoxContent from "components/ui/BoxContent";
import PageTitle from "components/ui/PageTitle";
import { APIGetTeachers } from "api/pages/teachers";
import { APIDeleteTeacher } from "api/pages/teacher";
import Loading from "components/ui/Loading";
import { niceUserName } from "utils/functions";
import CustomDataTable, { Order } from "components/ui/CustomDataTable";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import SearchForm, { IFormInputs } from "components/form/SearchForm";
import { SubmitHandler } from "react-hook-form";
import GenericButton from "components/ui/GenericButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import moment from "moment/moment";
import Icon from "../components/ui/Icon";

const Teachers = () => {
  const { update } = useAuth();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [teachers, setTeachers] = useState<any[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(15);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("last_name");
  const [query, setQuery] = useState<string>("");
  const [search, setSearch] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    APIGetTeachers({ page: `?page=${page + 1}`, sort: orderBy, order: order, search: query })
      .then((response) => {
        if (response.data.success) {
          // console.log("getTeachers OK", response);
          setTeachers(response.data.data.data);
          setTotalRows(response.data.data.total);
          setPerPage(response.data.data.per_page);
          setLoading(false);
        } else {
          update("error", { show: true, code: "A002" });
        }
      })
      .catch((err) => {
        // console.log("getTeachers ERR", err);
        update("error", { show: true, code: "A001" });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, order, orderBy, query, search]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setTeachers([]);
    setPage(newPage);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPage(0);
  };

  const handleSearch: SubmitHandler<IFormInputs> = (data) => {
    setQuery(data.keywords);
    setPage(0);
    setSearch(true);
  };

  const handleClearSearch = () => {
    setQuery("");
    setSearch(false);
    setPage(0);
  };

  const editTeacherHandler = (id: number) => {
    navigate(`/profesori/${id}/editare`);
  };

  const addTeacherHandler = () => {
    navigate(`/profesori/adauga`);
  };

  const deleteTeacher = (id: string, callback: Function) => {
    update("loading", { show: true, modal: true });
    APIDeleteTeacher({ id })
      .then((response) => {
        if (response.data.success) {
          // console.log("deleteTeacher OK", response);
          update("loading", { show: false });
          setTeachers(teachers.filter((item) => item.id.toString() !== id.toString()));
          // navigate("/profesori");
        } else {
          update("loading", { show: false });
          update("error", { show: true, code: "A002" });
        }
        callback();
      })
      .catch((err) => {
        // console.log("deleteTeacher ERR", err);
        update("loading", { show: false });
        update("error", { show: true, code: "A001" });
      });
  };

  const confirmDelete = (teacher: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    [key: string]: any;
  }) => {
    update("confirm", {
      show: true,
      closable: true,
      title: "Ești sigur că vrei să ștergi acest profesor?",
      message: (
        <>
          Acțiunea va șterge definitiv profesorul
          <strong> {niceUserName(teacher.first_name, teacher.last_name, teacher.email)}</strong>!
        </>
      ),
      returnFunction: (callback: Function) => {
        deleteTeacher(teacher.id.toString(), callback);
      },
      buttonYes: "Șterge",
      buttonNo: "Închide",
    });
  };

  return (
    <Box>
      <PageTitle
        actions={
          <GenericButton
            type="add"
            onClick={() => {
              addTeacherHandler();
            }}
          />
        }
      >
        Profesori
      </PageTitle>
      <BoxContent>
        <Box display="flex" justifyContent="end" width="100%" sx={{ marginBottom: { xs: 1, sm: 3 },}}>
          <SearchForm
            onSubmit={handleSearch}
            onChange={(e) => setQuery(e.target.value)}
            onClick={handleClearSearch}
            query={query}
          />
        </Box>
        {loading ? (
          <Loading show={true} />
        ) : teachers.length === 0 ? (
          <Box>Niciun profesor introdus în platformă.</Box>
        ) : (
          <CustomDataTable
            columns={[
              // {
              //   id: "id",
              //   label: "ID",
              //   numeric: false,
              //   disablePadding: true,
              // },
              {
                id: "last_name",
                label: "Nume și prenume",
                numeric: false,
                noWrap: true,
                disablePadding: false,
                sortable: true,
              },
              {
                id: "email",
                label: "E-mail",
                numeric: false,
                disablePadding: false,
                sortable: true,
              },
              {
                id: "classrooms",
                label: "Clasa",
                numeric: false,
                noWrap: true,
                tooltip: true,
                disablePadding: false,
              },
              {
                id: "email_verified_at",
                label: "Status",
                numeric: false,
                noWrap: true,
                disablePadding: true,
                sortable: true,
              },
              {
                id: "actions",
                label: "Acțiuni",
                numeric: true,
                noWrap: false,
                disablePadding: true,
              },
            ]}
            rows={teachers.map((teacher) => {
              return {
                // id: teacher.id.toString(),
                last_name: (
                  <Link to={`/profesori/${teacher.id}`}>
                    {niceUserName(teacher.first_name, teacher.last_name, teacher.email)}
                  </Link>
                ),
                email: teacher.email,
                classrooms: (
                  <>
                    {teacher.classrooms.map((classroom: any, index: any) => (
                      <Box key={index} mr={1}>
                        <Link to={`/clase/${classroom.id}`}>{classroom.name}</Link>
                      </Box>
                    ))}
                  </>
                ),
                email_verified_at:
                    teacher.email_verified_at !== null ? (
                        <Tooltip
                            title={
                              <Typography variant="body2" p={1} sx={{color: "primary.contrastText"}}>
                                Cont verificat la
                                <br /> {moment(teacher.email_verified_at).locale("ro").format("HH:mm, DD MMMM YYYY")}
                              </Typography>
                            }
                        >
                          <Box sx={{ width: "100%", paddingLeft: "16px" }}>
                            <Icon
                                icon="fas circle-check"
                                fixedWidth={true}
                                sx={{ fontSize: "1.5rem", color: "success.main" }}
                            />
                          </Box>
                        </Tooltip>
                    ) : (
                        <Tooltip
                            title={
                              <Typography variant="body2" p={1}>
                                Cont inactiv
                              </Typography>
                            }
                        >
                          <Box sx={{ width: "100%", paddingLeft: "16px" }}>
                            <Icon
                                icon="fas circle-xmark"
                                fixedWidth={true}
                                sx={{ fontSize: "1.5rem", color: "error.main" }}
                            />
                          </Box>
                        </Tooltip>
                    ),
                actions: (
                  <Box display="flex" justifyContent="end">
                    <Box mr={1}>
                      <GenericButton
                        type="edit-small"
                        onClick={() => {
                          editTeacherHandler(teacher.id);
                        }}
                      />
                    </Box>
                    <Box>
                      <GenericButton type="delete" onClick={() => confirmDelete(teacher)} />
                    </Box>
                  </Box>
                ),
              };
            })}
            rowsPerPage={perPage}
            page={page}
            totalRows={totalRows}
            onPageChange={handleChangePage}
            handleRequestSort={handleRequestSort}
            orderBy={orderBy}
            order={order}
          />
        )}
      </BoxContent>
    </Box>
  );
};

export default Teachers;
